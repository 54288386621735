import ProfileCard from "../components/ProfileCard"
import LeadSettingsCard from "../components/LeadSettingsCard"
// import LeadsCard from "../components/LeadsCard"
// import ResponsesCard from "../components/ResponsesCard"
import HelpCard from "../components/HelpCard"
import AboutCard from "../components/AboutCard"
import UnderDevelopment from "../../../components/UnderDevelopment"

const FreelancerOptions = () => {
    return (
        <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-x-6 gap-y-5 min-h-[480px]">
            <div className="xl:col-span-2 flex flex-col xl:flex-row gap-x-6 gap-y-5">
                <ProfileCard className="flex-1" />
                <LeadSettingsCard className="flex-1" />
            </div>
            <div className="space-y-4 flex-1">
                <AboutCard />
                {/* <LeadsCard /> */}
                {/* <ResponsesCard /> */}
                <HelpCard />
            </div>
            <UnderDevelopment />
        </div>
    )
}
export default FreelancerOptions