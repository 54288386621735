import { useState } from "react"

import officeIcon from "../../../../../assets/icons/request/office.png"
import NewRequestLayout from "../NewRequestLayout"
import RadioOptions from "./RadioOptions"
import { allBusinessSizeAsync } from "../../../../../slices/service"

const BusinessSize = ({ totalForms, formPosition, updateFormPosition, setBusinessSize, goBack }) => {
    const [value, setValue] = useState("")
    const [error, setError] = useState(false)

    const handleContinue = () => {
        if (value) {
            setBusinessSize(value)
        }
        else {
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 2000)
        }
    }
    return (
        <NewRequestLayout
            icon={officeIcon}
            description="Tell us about your organisation size."
            totalForms={totalForms} currentForm={formPosition}
            handleBackClick={goBack}
            handleContinueClick={handleContinue}
        >
            <div className="relative">
                <p className="text-versich-dark-blue text-lg font-semibold pb-3">What is your business / organization size?</p>
                <RadioOptions
                    actionAsync={allBusinessSizeAsync}
                    updateFormPosition={updateFormPosition}
                    value={value}
                    setValue={setValue}
                    error={error}
                />
            </div>
        </NewRequestLayout>
    )
}
export default BusinessSize
