import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../api"
import { CLIENT } from "../assets/constants"

export const profileCompletionScoreAsync = createAsyncThunk("account/profileCompletionScore", async (accountType) => {
    try {
        const { data } = accountType === CLIENT ? await api.clientProfileCompletionScore() : await api.freelancerProfileCompletionScore()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw error
    }
})

const profileCompletionSlice = createSlice({
    name: "profileCompletion",
    initialState: { score: 0 },
    reducers: {
        setScore: (state, action) => {
            state.score = action.score
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(profileCompletionScoreAsync.fulfilled, (state, action) => {
                state.score = action?.payload?.score
            })
            .addCase(profileCompletionScoreAsync.rejected, (state, action) => {
                state.score = 0
            })
    },
})
export const { setScore } = profileCompletionSlice.actions

export default profileCompletionSlice.reducer