import { useSelector, useDispatch } from "react-redux"
import { useSearchParams, useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import Cookies from "js-cookie"

import Meta from "../../../../../../components/Meta"
import FormLoader from "../../../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../../../components/Inputs/CSRFTokenField"
import BusinessSize from "../../components/BusinessSize"
import ServiceNeed from "./components/ServiceNeed"
import ChoiceOfTool from "./components/ChoiceOfTool"
import ProjectDuration from "../../components/ProjectDuration"
import Budget from "../../components/Budget"
import ClientDetails from "../../components/ClientDetails"
import Description from "../../components/Description"
import {
    loginPath, newRequestPath, requestConfirmationPath,
    COOKIE_NEW_REQUEST_DAA_BU,
    TOASTTIMER, TOASTTYPE_ERROR
} from "../../../../../../assets/constants"
import { createRequestAsync } from "../../../../../../slices/client/service/dataAndAnalytics/business"
import toastify from "../../../../../../utils/toastify"

const BusinessDataAndAnalytics = () => {
    const { user } = useSelector(state => state.auth)
    const [searchParams, _] = useSearchParams()

    const [csrfToken, setCsrfToken] = useState("")
    const [formPosition, setFormPosition] = useState(0)
    const [businessSize, setBusinessSize] = useState("")
    const [need, setNeed] = useState("")
    const [otherNeed, setOtherNeed] = useState(null)
    const [tools, setTools] = useState(null)
    const [projectDuration, setProjectDuration] = useState("")
    const [otherProjectDuration, setOtherProjectDuration] = useState(null)
    const [currency, setCurrency] = useState("")
    const [budget, setBudget] = useState("")
    const [otherBudget, setOtherBudget] = useState(null)
    const [clientDetails, setClientDetails] = useState(null)
    const [description, setDescription] = useState("")
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Prefill data from cookie
    useEffect(() => {
        const data = Cookies.get(COOKIE_NEW_REQUEST_DAA_BU)
        if (!data) {
            return
        }
        const parsedData = JSON.parse(data)
        parsedData.businessSize && setBusinessSize(parsedData.businessSize)
        parsedData.need && setNeed(parsedData.need)
        parsedData.otherNeed && setOtherNeed(parsedData.otherNeed)
        parsedData.tools && setTools(parsedData.tools)
        parsedData.projectDuration && setProjectDuration(parsedData.projectDuration)
        parsedData.otherProjectDuration && setOtherProjectDuration(parsedData.otherProjectDuration)
        parsedData.currency && setCurrency(parsedData.currency)
        parsedData.budget && setBudget(parsedData.budget)
        parsedData.otherBudget && setOtherBudget(parsedData.otherBudget)
        parsedData.clientEmail && setClientDetails({
            email: parsedData.clientEmail,
            name: parsedData.clientName,
        })
        parsedData.description && setDescription(parsedData.description)
    }, [])

    // Prefill client details
    useEffect(() => {
        if (!user) {
            return
        }
        setClientDetails({
            email: user.email,
            ...(user.full_name && { name: user.full_name }),
        })
    }, [user])

    const location = useLocation()
    const currentPath = window.encodeURIComponent(location.pathname)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleSubmit = async (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        if (!description) {
            setError(true)
            return setTimeout(() => {
                setError(false)
            }, 2000)
        }

        setLoading(true)
        const formData = {
            businessSize, need,
            ...(otherNeed && { otherNeed }),
            tools,
            projectDuration,
            ...(otherProjectDuration && { otherProjectDuration }),
            currency,
            budget,
            ...(otherBudget && { otherBudget }),
            clientEmail: clientDetails.email,
            clientName: clientDetails.name,
            description,
            token: csrfToken
        }
        if (user === null) {
            Cookies.set(COOKIE_NEW_REQUEST_DAA_BU, JSON.stringify(formData), { expires: 7 })
            return navigate(`${loginPath}?next=${currentPath}&email=${formData.clientEmail}&name=${formData.clientName}`)
        }
        Cookies.remove(COOKIE_NEW_REQUEST_DAA_BU)
        await dispatch(createRequestAsync(formData))
            .unwrap()
            .then((data) => {
                if (data.payload) {
                    const dynamicRequestConfimationPath = requestConfirmationPath.replace(":id", data.payload.requestID).replace(":pid", data.payload.pid)
                    navigate(dynamicRequestConfimationPath)
                }
                else toastify("Something went wrong, while creating request!", TOASTTYPE_ERROR, submitBtn)
            })
            .catch(error => {
                const data = JSON.parse(error?.message)
                setErrorMsg(data.message)
                setTimeout(() => {
                    setErrorMsg("")
                    submitBtn.disabled = false
                }, TOASTTIMER)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const totalForms = 7
    return (
        <div className="bg-versich-primary-bg mb-12 py-10 md:py-14 px-3">
            <Meta title="New Request - Data and Analytics Service" description="Data and analytics new request" />
            <form className="flex flex-col" onSubmit={handleSubmit} onKeyDown={e => e.key === "Enter" && !clientDetails && e.preventDefault()}>
                <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                {!businessSize
                    ? <BusinessSize totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(1)} setBusinessSize={setBusinessSize} goBack={() => navigate(newRequestPath + "data-and-analytics/")} />
                    : !need
                        ? <ServiceNeed totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(2)} defaultValue={searchParams.get("need")} setNeed={setNeed} setOtherNeed={setOtherNeed} goBack={() => setBusinessSize("")} />
                        : !tools
                            ? <ChoiceOfTool totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(3)} setTools={setTools} goBack={() => setNeed("")} />
                            : !projectDuration
                                ? <ProjectDuration totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(4)} setProjectDuration={setProjectDuration} setOtherProjectDuration={setOtherProjectDuration} goBack={() => setTools(null)} />
                                : !budget
                                    ? <Budget totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(5)} setCurrency={setCurrency} setBudget={setBudget} setOtherBudget={setOtherBudget} goBack={() => setProjectDuration("")} />
                                    : !clientDetails
                                        ? <ClientDetails totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(6)} clientDetails={clientDetails} setClientDetails={setClientDetails} goBack={() => setBudget("")} />
                                        : <Description totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(7)} description={description} setDescription={setDescription} error={error} goBack={() => setClientDetails(null)} />
                }
            </form>
            <div className="max_screen_w">
                {errorMsg && <div className="text-red-500 text-sm text-right max-w-[900px] !mt-10 mx-auto">{errorMsg}</div>}
            </div>
            {loading && <FormLoader />}
        </div>
    )
}
export default BusinessDataAndAnalytics