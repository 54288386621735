import { useSelector, useDispatch } from "react-redux"
import { useSearchParams, useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import Cookies from "js-cookie"

import Meta from "../../../../../components/Meta"
import FormLoader from "../../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../../components/Inputs/CSRFTokenField"
import WebsiteRequirement from "./components/WebsiteRequirement"
import WebsiteNeed from "./components/WebsiteNeed"
import BusinessType from "../components/BusinessType"
import IndustryType from "../components/IndustryType"
import ProjectDuration from "../components/ProjectDuration"
import Budget from "../components/Budget"
import HiringDecision from "../components/HiringDecision"
import ClientDetails from "../components/ClientDetails"
import Description from "../components/Description"
import {
    loginPath, newRequestPath, requestConfirmationPath,
    COOKIE_NEW_REQUEST_WDAD,
    TOASTTIMER, TOASTTYPE_ERROR
} from "../../../../../assets/constants"
import { createRequestAsync } from "../../../../../slices/client/service/webDesignAndDevelopment"
import toastify from "../../../../../utils/toastify"

const WebDesignAndDevelopment = () => {
    const { user } = useSelector(state => state.auth)
    const [searchParams, _] = useSearchParams()

    const [csrfToken, setCsrfToken] = useState("")
    const [formPosition, setFormPosition] = useState(0)
    const [requirement, setRequirement] = useState("")
    const [need, setNeed] = useState("")
    const [otherNeed, setOtherNeed] = useState(null)
    const [businessType, setBusinessType] = useState("")
    const [otherBusinessType, setOtherBusinessType] = useState(null)
    const [industryType, setIndustryType] = useState("")
    const [otherIndustryType, setOtherIndustryType] = useState(null)
    const [projectDuration, setProjectDuration] = useState("")
    const [otherProjectDuration, setOtherProjectDuration] = useState(null)
    const [currency, setCurrency] = useState("")
    const [budget, setBudget] = useState("")
    const [otherBudget, setOtherBudget] = useState(null)
    const [hiringDecision, setHiringDecision] = useState("")
    const [otherHiringDecision, setOtherHiringDecision] = useState(null)
    const [clientDetails, setClientDetails] = useState(null)
    const [description, setDescription] = useState("")
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Prefill data from cookie
    useEffect(() => {
        const data = Cookies.get(COOKIE_NEW_REQUEST_WDAD)
        if (!data) {
            return
        }
        const parsedData = JSON.parse(data)
        parsedData.requirement && setRequirement(parsedData.requirement)
        parsedData.need && setNeed(parsedData.need)
        parsedData.otherNeed && setOtherNeed(parsedData.otherNeed)
        parsedData.businessType && setBusinessType(parsedData.businessType)
        parsedData.otherBusinessType && setOtherBusinessType(parsedData.otherBusinessType)
        parsedData.industryType && setIndustryType(parsedData.industryType)
        parsedData.otherIndustryType && setOtherIndustryType(parsedData.otherIndustryType)
        parsedData.projectDuration && setProjectDuration(parsedData.projectDuration)
        parsedData.otherProjectDuration && setOtherProjectDuration(parsedData.otherProjectDuration)
        parsedData.currency && setCurrency(parsedData.currency)
        parsedData.budget && setBudget(parsedData.budget)
        parsedData.otherBudget && setOtherBudget(parsedData.otherBudget)
        parsedData.hiringDecision && setHiringDecision(parsedData.hiringDecision)
        parsedData.otherHiringDecision && setOtherHiringDecision(parsedData.otherHiringDecision)
        parsedData.clientEmail && setClientDetails({
            email: parsedData.clientEmail,
            name: parsedData.clientName,
        })
        parsedData.description && setDescription(parsedData.description)
    }, [])

    // Prefill client details
    useEffect(() => {
        if (!user) {
            return
        }
        setClientDetails({
            email: user.email,
            ...(user.full_name && { name: user.full_name }),
        })
    }, [user])

    const location = useLocation()
    const currentPath = window.encodeURIComponent(location.pathname)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleSubmit = async (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        if (!description) {
            setError(true)
            return setTimeout(() => {
                setError(false)
            }, 2000)
        }

        setLoading(true)
        const formData = {
            requirement, need,
            ...(otherNeed && { otherNeed }),
            businessType,
            ...(otherBusinessType && { otherBusinessType }),
            industryType,
            ...(otherIndustryType && { otherIndustryType }),
            projectDuration,
            ...(otherProjectDuration && { otherProjectDuration }),
            currency, budget,
            ...(otherBudget && { otherBudget }),
            hiringDecision,
            ...(otherHiringDecision && { otherHiringDecision }),
            clientEmail: clientDetails.email,
            clientName: clientDetails.name,
            description,
            token: csrfToken
        }
        if (user === null) {
            Cookies.set(COOKIE_NEW_REQUEST_WDAD, JSON.stringify(formData), { expires: 7 })
            return navigate(`${loginPath}?next=${currentPath}&email=${formData.clientEmail}&name=${formData.clientName}`)
        }
        Cookies.remove(COOKIE_NEW_REQUEST_WDAD)
        await dispatch(createRequestAsync(formData))
            .unwrap()
            .then((data) => {
                if (data.payload) {
                    const dynamicRequestConfimationPath = requestConfirmationPath.replace(":id", data.payload.requestID).replace(":pid", data.payload.pid)
                    navigate(dynamicRequestConfimationPath)
                }
                else toastify("Something went wrong, while creating request!", TOASTTYPE_ERROR, submitBtn)
            })
            .catch(error => {
                const data = JSON.parse(error?.message)
                setErrorMsg(data.message)
                setTimeout(() => {
                    setErrorMsg("")
                    submitBtn.disabled = false
                }, TOASTTIMER)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const totalForms = 9
    return (
        <div className="bg-versich-primary-bg mb-12 py-10 md:py-14 px-3">
            <Meta title="New Request - Web Design and Development Service" description="Web design and development new request" />
            <form className="flex flex-col" onSubmit={handleSubmit} onKeyDown={e => e.key === "Enter" && !clientDetails && e.preventDefault()}>
                <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                {!requirement
                    ? <WebsiteRequirement totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(1)} defaultValue={searchParams.get("requirement")} setRequirement={setRequirement} goBack={() => navigate(newRequestPath)} />
                    : !need
                        ? <WebsiteNeed totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(2)} defaultValue={searchParams.get("need")} setNeed={setNeed} setOtherNeed={setOtherNeed} goBack={() => setRequirement("")} />
                        : !businessType
                            ? <BusinessType totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(3)} setBusinessType={setBusinessType} setOtherBusinessType={setOtherBusinessType} goBack={() => setNeed("")} />
                            : !industryType
                                ? <IndustryType totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(4)} setIndustryType={setIndustryType} setOtherIndustryType={setOtherIndustryType} goBack={() => setBusinessType("")} />
                                : !projectDuration
                                    ? <ProjectDuration totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(5)} setProjectDuration={setProjectDuration} setOtherProjectDuration={setOtherProjectDuration} goBack={() => setIndustryType("")} />
                                    : !budget
                                        ? <Budget totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(6)} setCurrency={setCurrency} setBudget={setBudget} setOtherBudget={setOtherBudget} goBack={() => setProjectDuration("")} />
                                        : !hiringDecision
                                            ? <HiringDecision totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(7)} setHiringDecision={setHiringDecision} setOtherHiringDecision={setOtherHiringDecision} goBack={() => setBudget("")} />
                                            : !clientDetails
                                                ? <ClientDetails totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(8)} clientDetails={clientDetails} setClientDetails={setClientDetails} goBack={() => setHiringDecision("")} />
                                                : <Description totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(9)} description={description} setDescription={setDescription} error={error} goBack={() => setClientDetails(null)} />
                }
            </form>
            <div className="max_screen_w">
                {errorMsg && <div className="text-red-500 text-sm text-right max-w-[900px] !mt-10 mx-auto">{errorMsg}</div>}
            </div>
            {loading && <FormLoader />}
        </div>
    )
}
export default WebDesignAndDevelopment


/*
: <div className="max_screen_w w-full flex justify-between">
    <button className="bg-slate-100 hover:bg-slate-200 text-gray-900 border border-versich-border/20 rounded-md py-2 lg:py-2.5 px-4 lg:px-6" type="button" onClick={() => setDescription("")}>Back</button>
    <button className="bg-versich-blue hover:bg-versich-blue-hover text-white rounded-md py-2 lg:py-2.5 px-4 lg:px-6" type="submit">Submit</button>
</div>
*/