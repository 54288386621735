import { useFormContext } from "react-hook-form"
import { useEffect } from "react"

const TextAreaField = ({ name, label, placeholder, rules, onChange, defaultValue, readOnly }) => {
    const { register, formState, setValue } = useFormContext()
    const { errors } = formState

    useEffect(() => {
        setValue(name, defaultValue)
    }, [defaultValue, name])

    const handleChange = (e) => {
        setValue(name, e.target.value)
        onChange && onChange(e)
    }
    return (
        <div className="space-y-2">
            {label && <label htmlFor={name} className="text-versich-label block text-sm text-start">{label}</label>}
            <textarea
                className={"w-full min-h-16 max-h-48 rounded-md border-[1px] border-versich-border px-3 py-2 " + (errors[name] ? "border-red-500" : "")}
                name={name}
                {...register(name, rules)}
                value={defaultValue}
                placeholder={placeholder}
                onChange={handleChange}
                readOnly={readOnly}
            />
            {errors[name] &&
                <div className="text-sm text-red-500 text-left">
                    {errors[name].message}
                </div>
            }
        </div>
    )
}
export default TextAreaField
