import { useState, useRef, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { newRequestPath } from "../../../assets/constants"

const SearchSuggestionsInput = ({ onChange, value, setValue, suggestions }) => {
    const [isOpen, setIsOpen] = useState(false)

    const navigate = useNavigate()
    const handleOptionSelection = (option) => {
        setValue(option.service)
        setIsOpen(false)
        navigate(`${newRequestPath + option.uid}/`)
    }

    // Handle Outside click
    const comboInputRef = useRef(null)
    useEffect(() => {
        const handleClickOutside = (event) => {
            comboInputRef.current && !comboInputRef.current.contains(event.target)
                && setIsOpen(false)
        }
        document.addEventListener("click", handleClickOutside)
        return () => {
            document.removeEventListener("click", handleClickOutside)
        }
    }, [])
    return (
        <div className="flex-1 flex flex-col gap-y-4" ref={comboInputRef}>
            <div className="relative z-50">
                <input
                    className="focus:outline-versich-dark-blue text-black text-base md:text-xl w-full min-w-0 px-3 lg:px-[15px] py-2 lg:py-3 border-2 rounded-lg required"
                    name="selectedServices"
                    type="text"
                    onChange={onChange}
                    value={value}
                    placeholder="What service are you looking for?"
                    onClick={() => setIsOpen(true)}
                />
                {isOpen && suggestions.length > 0 &&
                    <div className="absolute bg-white overflow-y-auto max-h-48 w-full rounded-bl-md rounded-br-md border-[1px] border-versich-border">
                        {suggestions.map((option) => (
                            <ul key={option.uid}>
                                <li className="text-versich-darktext-color p-2 border-b border-gray-200" onClick={() => handleOptionSelection(option)}>{option.service}</li>
                            </ul>
                        ))}
                    </div>
                }
            </div>
        </div>
    )
}
export default SearchSuggestionsInput
