import { useState } from "react"

import checkListIcon from "../../../../../assets/icons/request/checklist.png"
import NewRequestLayout from "../NewRequestLayout"
import RadioOptions from "./RadioOptions"
import { allBusinessTypeAsync } from "../../../../../slices/service"

const BusinessType = ({ totalForms, formPosition, updateFormPosition, setBusinessType, setOtherBusinessType, goBack }) => {
    const [value, setValue] = useState("")
    const [otherValue, setOtherValue] = useState("")
    const [error, setError] = useState(false)
    const [otherInputError, setOtherInputError] = useState(false)

    const handleContinue = () => {
        if (value === "other") {
            if (otherValue) {
                setBusinessType(value)
                setOtherBusinessType(otherValue)
            }
            else {
                setOtherInputError(true)
                setTimeout(() => {
                    setOtherInputError(false)
                }, 2000)
            }
        }
        else if (value) {
            setBusinessType(value)
        }
        else {
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 2000)
        }
    }
    return (
        <NewRequestLayout
            icon={checkListIcon}
            description="Specify the type of business this website is intended for."
            totalForms={totalForms} currentForm={formPosition}
            handleBackClick={goBack}
            handleContinueClick={handleContinue}
        >
            <div className="relative">
                <p className="text-versich-dark-blue text-lg font-semibold pb-3">What type of business is this for?</p>
                <RadioOptions
                    actionAsync={allBusinessTypeAsync}
                    updateFormPosition={updateFormPosition}
                    value={value}
                    setValue={setValue}
                    error={error}
                    setOtherValue={setOtherValue}
                    otherInputError={otherInputError}
                />
            </div>
        </NewRequestLayout>
    )
}
export default BusinessType
