import { GoRead, GoUnread } from "react-icons/go"

const HelpCard = () => {
    return (
        <div className="bg-white shadow-card px-4 py-4 sm:px-6 sm:py-6 rounded-xl space-y-4 text-start">
            <div className="text-start text-lg lg:text-xl font-semibold text-versich-dark-blue">Help</div>
            <div className="flex items-center gap-x-3">
                <div className="bg-[#D9EBFC] rounded-full p-3 text-versich-dark-blue">
                    <GoRead />
                </div>
                <div className="text-sm">Email <a className="text-versich-blue underline underline-offset-2" href="mailto:Hello@versimarket.com">Hello@versimarket.com</a></div>
            </div>
            <div className="flex items-center gap-x-3">
                <div className="bg-[#D9EBFC] rounded-full p-3 text-versich-dark-blue">
                    <GoUnread />
                </div>
                <div className="text-sm">Call <a className="text-versich-blue underline underline-offset-2" href="tel:+447782350419">+44 7782 350419</a> open 24 hours a day, 7 days a week</div>
            </div>
        </div>
    )
}
export default HelpCard