import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import Meta from "../../../../../components/Meta"
import NewRequestLayout from "../NewRequestLayout"
import applicationIcon from "../../../../../assets/icons/request/application.png"
import personalIcon from "../../../../../assets/icons/request/personal-dataanalytics.png"
import businessIcon from "../../../../../assets/icons/request/business-dataanalytics.png"
import { newRequestPath } from "../../../../../assets/constants"

const DataAndAnalytics = () => {
    const [value, setValue] = useState("")
    const [error, setError] = useState(false)

    const location = useLocation()
    const navigate = useNavigate()
    const handleContinue = () => {
        if (value) {
            navigate(`${location.pathname + value}/`)
        }
        else {
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 2000)
        }
    }
    return (
        <div className="bg-versich-primary-bg mb-12 py-10 md:py-14 px-3">
            <Meta title="New Request - Web Design and Development Service" description="Web design and development new request" />
            <div className="flex flex-col">
                <NewRequestLayout
                    icon={applicationIcon}
                    description="Tell us your project purpose."
                    handleBackClick={() => navigate(newRequestPath)}
                    handleContinueClick={handleContinue}
                >
                    <div className="relative">
                        <p className=" text-versich-dark-blue text-lg font-semibold pb-3">What do you need data analytics services for?</p>
                        <p className="text-sm pb-6">Once selected, please click ‘continue’</p>
                        <div className="grid grid-cols-1 sm404:grid-cols-2 gap-5 pb-5">
                            {dataAndAnalyticsOptions.map(option => (
                                <div
                                    key={option.uid}
                                    className={`flex-1 flex flex-col justify-center items-center gap-y-2 h-44 py-4 px-3 border-[3px] ${value === option.uid
                                        ? "border-versich-blue/80 bg-versich-blue/15"
                                        : "border-gray-100 hover:border-versich-blue/20"
                                        } cursor-pointer shadow-sticker rounded-xl`
                                    }
                                    onClick={() => setValue(option.uid)}
                                >
                                    <input className="appearance-none" name="analyticsNeed" type="radio" value={option.uid} />
                                    <img className="w-16 h-16" src={option.icon} alt="" />
                                    <span className="text-versich-dark-blue text-center text-xs md:text-sm max-w-[200px]">{option.title}</span>
                                </div>
                            ))}
                        </div>
                        {error && <p className="text-red-500 text-start text-sm mb-3">Please select an option</p>}
                    </div>
                </NewRequestLayout>
            </div>
        </div>
    )
}
const dataAndAnalyticsOptions = [
    {
        uid: "personal",
        title: "Myself, Personal projects",
        icon: personalIcon,
    },
    {
        uid: "business",
        title: "Team, Business, Company",
        icon: businessIcon,
    },
]
export default DataAndAnalytics