import { useParams, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { FaRegEye } from "react-icons/fa"

import Meta from "../../../../components/Meta"
import FreelancerCard from "../../../../components/Requests/FreelancerCard"
import ContactFreelancerDialog from "../../../../components/Requests/ContactFreelancerDialog"
import { serviceRelatedFreelancersAsync } from "../../../../slices/freelancer"
import { requestDetailsPath } from "../../../../assets/constants"
import { fetchServiceId } from "./utils"

const RequestConfirmation = () => {
    const { id, pid } = useParams()
    const [freelancers, setFreelancers] = useState([])
    const [freelancerEmail, setFreelancerEmail] = useState("")
    const [messageSent, setMessageSent] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {
        const serviceId = fetchServiceId(id)
        dispatch(serviceRelatedFreelancersAsync(serviceId))
            .unwrap()
            .then(data => {
                setFreelancers(data.recommendedFreelancers)
            })
            .catch(() => { })
    }, [])

    const navigate = useNavigate()
    const viewRequest = () => {
        navigate(requestDetailsPath.replace(":id", id))
    }
    return (
        <div className="bg-versich-primary-bg px-6 md:px-16 lg:px-28 py-10">
            <Meta title="Request Confirmation" description="VersiMarket user dashboard" />
            <div className="max_screen_w space-y-10">
                <div className="bg-white shadow-card text-versich-dark-blue font-semibold text-start flex justify-between flex-col sm:flex-row gap-2 w-full px-4 py-4 sm:px-6 sm:py-6 rounded-xl">
                    <p>Request created with pid: {pid}</p>
                    <button className="text-versich-blue hover:text-versich-blue-hover text-sm inline-flex items-center gap-1.5" onClick={viewRequest} title="View Request">
                        <FaRegEye className="w-3.5 md:w-4 h-3.5 md:h-4" /> View request
                    </button>
                </div>
                {messageSent &&
                    <div className="bg-cyan-100 shadow-sticker w-full !mt-5 px-4 py-2 sm:px-6 sm:py-4 font-semibold text-start text-versich-dark-blue rounded-xl">
                        Message sent to freelancer.
                    </div>
                }
                <div className="bg-white shadow-card rounded-xl">
                    <div className="px-5 md:px-10 py-6 md:py-12 space-y-4">
                        <div className="text-start space-y-5">
                            <p className="text-xl lg:text-2xl text-versich-dark-blue font-semibold pb-3">Recommended Freelancer</p>
                            {freelancers.map(freelancer => (
                                <FreelancerCard user={freelancer} contactExpertClick={() => setFreelancerEmail(freelancer.email)} key={freelancer.email} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <ContactFreelancerDialog freelancerEmail={freelancerEmail} setMessageSent={setMessageSent} />
        </div>
    )
}
export default RequestConfirmation