import * as api from "../api"
import * as actionType from "./types"

export const signupUser = (accounttype, firstname, lastname, email, password, token) => async (dispatch) => {
    try {
        const { data } = await api.register({ accounttype, firstname, lastname, email, password, csrfmiddlewaretoken: token })
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        await dispatch(getUser())
        return data
    }
    catch (error) {
        if (error.response && error.response.status) {
            throw new Error(error.response.data.message)
        } else {
            throw Error(error)
        }
    }
}

export const loginUser = (email, password, token) => async (dispatch) => {
    try {
        const { data } = await api.login({ email, password, csrfmiddlewaretoken: token })
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        await dispatch(getUser())
        return data
    }
    catch (error) {
        if (error.response && error.response.status) {
            throw new Error(error.response.data.message)
        } else {
            throw Error(error)
        }
    }
}

export const getUser = () => async (dispatch) => {
    try {
        const { data } = await api.getUser()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        dispatch({ type: actionType.AUTH, data })
    } catch (error) {
        throw Error()
    }
}

export const checkAuth = () => async (dispatch) => {
    try {
        const { data } = await api.getUser()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        dispatch({ type: actionType.AUTH, data })
        dispatch({ type: actionType.AUTH_VERIFICATION_FULFILLED })
    }
    catch (error) {
        dispatch({ type: actionType.AUTH_VERIFICATION_FAILED })
        return null
    }
}

export const logoutUser = () => async (dispatch) => {
    try {
        const { data } = await api.logout()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        dispatch({ type: actionType.LOGOUT })
    } catch (error) {
        return null
    }
}
