import { useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom"

import { CLIENT, FREELANCER, loginPath, SS_ACCOUNT_TYPE } from "../../../assets/constants"
import AccountSelectionFlow from "../../AccountSelectionFlow/AccountSelectionFlow"
import { useState } from "react"

const ClientOnlyRoutes = () => {
    const { user } = useSelector(state => state.auth)
    const [accountType, setAccountType] = useState(window.sessionStorage.getItem(SS_ACCOUNT_TYPE.key))
    if (user) {
        return <Outlet />
    }
    if ([CLIENT, FREELANCER].includes(accountType)) {
        if (accountType === CLIENT) {
            return <Outlet />
        }
        return <Navigate to={loginPath} replace />
    }
    return <AccountSelectionFlow setAccountType={setAccountType} />
}
export default ClientOnlyRoutes