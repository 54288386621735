import axios from "axios"

const API = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
})
const getReqConfig = (data) => {
    return {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-csrftoken": data.csrfmiddlewaretoken,
        },
    }
}

export const csrfToken = () => API.get("/csrf/")

// Auth
export const register = (data) => API.post("/auth/register/", data, getReqConfig(data))
export const login = (data) => API.post("/auth/login/", data, getReqConfig(data))
export const getUser = () => API.get("/auth/me/")
export const logout = () => API.get("/auth/logout/")
export const verifyEmail = (token) => API.get(`/auth/verify/account/${token}/`)
export const resendVerificationEmail = (data) => API.post("/auth/verify/account/resend/email/", null, getReqConfig(data))

// Social Auth
export const authenticateGoogleUser = (searchQuery) => API.get(`/auth/social-accounts/google/callback/${searchQuery}`)


// Service
export const searchService = (data) => API.post("/service/search/", data, getReqConfig(data))
export const getAllBusinessType = () => API.get("/service/business-type/all/")
export const getAllIndustryType = () => API.get("/service/industry-type/all/")
export const getAllProjectDuration = () => API.get("/service/project-duration/all/")
export const getAllCurrency = () => API.get("/service/currency/all/")
export const getAllEstimatedBudget = () => API.get("/service/estimated-budget/all/")
export const getAllHiringDecision = () => API.get("/service/hiring-decision/all/")
export const getAllBusinessSize = () => API.get("/service/business-size/all/")
export const websiteRequirementsWDAD = (params) => API.get(`/service/web-design-and-development/website-requirement/all/${params}`)
export const websiteNeedsWDAD = (params) => API.get(`/service/web-design-and-development/website-need/all/${params}`)
export const createRequestWDAD = (data) => API.post("service/web-design-and-development/request/new/", data, getReqConfig(data))
export const getRequestWDAD = (refId) => API.get(`service/web-design-and-development/request/${refId}/`)
export const mobileProjectTypesMA = (params) => API.get(`/service/mobile-app/mobile-projecttype/all/${params}`)
export const mobileMonetizationStrategyMA = (params) => API.get(`/service/mobile-app/monetization-strategy/all/${params}`)
export const mobileAppPlatformsMA = (params) => API.get(`/service/mobile-app/app-platform/all/${params}`)
export const mobileProjectNeedsMA = (params) => API.get(`/service/mobile-app/mobile-projectneeds/all/${params}`)
export const createRequestMA = (data) => API.post("/service/mobile-app/request/new/", data, getReqConfig(data))
export const getRequestMA = (refId) => API.get(`/service/mobile-app/request/${refId}/`)
export const businessServiceNeedsDAA = (params) => API.get(`/service/data-and-analytics/business/service-need/all/${params}`)
export const businessChoiceOfToolsDAA = (params) => API.get(`/service/data-and-analytics/business/choice-of-tool/all/${params}`)
export const businessCreateRequestDAA = (data) => API.post("/service/data-and-analytics/business/request/new/", data, getReqConfig(data))
export const businessGetRequestMA = (refId) => API.get(`/service/data-and-analytics/business/request/${refId}/`)
export const personalAssistanceNeedsDAA = (params) => API.get(`/service/data-and-analytics/personal/assistance-need/all/${params}`)
export const personalChoiceOfToolsDAA = (params) => API.get(`/service/data-and-analytics/personal/choice-of-tool/all/${params}`)
export const personalCreateRequestDAA = (data) => API.post("/service/data-and-analytics/personal/request/new/", data, getReqConfig(data))
export const personalGetRequestMA = (refId) => API.get(`/service/data-and-analytics/personal/request/${refId}/`)
export const softwareTypesSD = (params) => API.get(`/service/software-development/software-type/all/${params}`)
export const softwareNaturesSD = (params) => API.get(`/service/software-development/software-nature/all/${params}`)
export const operatingSystemsSD = (params) => API.get(`/service/software-development/operating-system/all/${params}`)
export const industryTypesSD = (params) => API.get(`/service/software-development/industry-type/all/${params}`)
export const serviceEngagementsSD = (params) => API.get(`/service/software-development/service-engagement/all/${params}`)
export const createRequestSD = (data) => API.post("/service/software-development/request/new/", data, getReqConfig(data))
export const getRequestSD = (refId) => API.get(`/service/software-development/request/${refId}/`)
export const FPandARevolutionServicesFT = (params) => API.get(`/service/finance-transformation/fp-and-a-revolution/service/all/${params}`)
export const FPandARevolutionChoiceOfToolsFT = (params) => API.get(`/service/finance-transformation/fp-and-a-revolution/choice-of-tool/all/${params}`)
export const FPandARevolutionCreateRequestFT = (data) => API.post("/service/finance-transformation/fp-and-a-revolution/request/new/", data, getReqConfig(data))
export const FPandARevolutionGetRequestSD = (refId) => API.get(`/service/finance-transformation/fp-and-a-revolution/request/${refId}/`)
export const FRAAServices_FT = (params) => API.get(`/service/finance-transformation/finance-reporting-and-advanced-analytics/service/all/${params}`)
export const FRAAChoiceOfTools_FT = (params) => API.get(`/service/finance-transformation/finance-reporting-and-advanced-analytics/choice-of-tool/all/${params}`)
export const FRAACreateRequestFT = (data) => API.post("/service/finance-transformation/finance-reporting-and-advanced-analytics/request/new/", data, getReqConfig(data))
export const FRAAGetRequestSD = (refId) => API.get(`/service/finance-transformation/finance-reporting-and-advanced-analytics/request/${refId}/`)
export const EnterpriseChoiceOfTools_FT = (params) => API.get(`/service/finance-transformation/enterprise/choice-of-tool/all/${params}`)
export const EnterpriseCreateRequest_FT = (data) => API.post("/service/finance-transformation/enterprise/request/new/", data, getReqConfig(data))
export const EnterpriseGetRequestSD = (refId) => API.get(`/service/finance-transformation/enterprise/request/${refId}/`)
export const SystemAdministrationChoiceOfTools_FT = (params) => API.get(`/service/finance-transformation/system-administration/choice-of-tool/all/${params}`)
export const SystemAdministrationCreateRequest_FT = (data) => API.post("/service/finance-transformation/system-administration/request/new/", data, getReqConfig(data))
export const SystemAdministrationGetRequestSD = (refId) => API.get(`/service/finance-transformation/system-administration/request/${refId}/`)
export const DigitalTransformationServices_FT = (params) => API.get(`/service/finance-transformation/digital-transformation/service/all/${params}`)
export const DigitalTransformationCreateRequest_FT = (data) => API.post("/service/finance-transformation/digital-transformation/request/new/", data, getReqConfig(data))
export const DigitalTransformationGetRequestSD = (refId) => API.get(`/service/finance-transformation/digital-transformation/request/${refId}/`)
export const createRequest_FT = (data) => API.post("/service/finance-transformation/request/new/", data, getReqConfig(data))
export const getRequestFT = (refId) => API.get(`/service/finance-transformation/request/${refId}/`)
export const expertsMLAI = (params) => API.get(`/service/machine-learning-and-ai/expert/all/${params}`)
export const createRequestMLAI = (data) => API.post("/service/machine-learning-and-ai/request/new/", data, getReqConfig(data))
export const getRequestMLAI = (refId) => API.get(`/service/machine-learning-and-ai/request/${refId}/`)
export const allUserRequest = () => API.get("/service/user-request/all/")
export const latestUserRequest = () => API.get("/service/user-request/latest/")


// Account
export const getAllCounties = () => API.get("/account/country/all/")
export const getCountryStates = (iso3) => API.get(`/account/country/${iso3}/states/all/`)
export const clientProfileCompletionScore = () => API.get("/account/client/profile-completion/score/")
export const clientAboutCreate = (data) => API.post("/account/client/about/create/", data, getReqConfig(data))
export const clientAboutUpdate = (data) => API.put("/account/client/about/update/", data, getReqConfig(data))
export const freelancerProfileCompletionScore = () => API.get("/account/freelancer/profile-completion/score/")
export const freelancerPrimaryServiceSet = (data) => API.post("/account/freelancer/primary-service/set/", data, getReqConfig(data))
export const freelancerPrimaryServiceUpdate = (data) => API.put("/account/freelancer/primary-service/update/", data, getReqConfig(data))
export const freelancerLeadsLocationCreate = (data) => API.post("/account/freelancer/leads-location/create/", data, getReqConfig(data))
export const freelancerLeadsLocationUpdate = (data) => API.put("/account/freelancer/leads-location/update/", data, getReqConfig(data))
export const freelancerAboutCreate = (data) => API.post("/account/freelancer/about/create/", data, getReqConfig(data))
export const freelancerAboutUpdate = (data) => API.put("/account/freelancer/about/update/", data, getReqConfig(data))
export const otherRemainingServices = () => API.get("/account/freelancer/other-services/remaining/")
export const freelancerOtherServiceUpdate = (data) => API.patch("/account/freelancer/other-service/update/", data, getReqConfig(data))
export const serviceRelatedFreelancers = (id) => API.get(`/account/freelancer/related-to/service/${id}/`)


// Message
export const directMessageToFreelancer = (data) => API.post("/message/direct-message/", data, getReqConfig(data))