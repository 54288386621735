import { useState } from "react"

import checkListIcon from "../../../../../../../assets/icons/request/checklist.png"
import NewRequestLayout from "../../../NewRequestLayout"
import RadioOptions from "../../../components/RadioOptions"
import { allAssistanceNeedAsync } from "../../../../../../../slices/client/service/dataAndAnalytics/personal"

const AssistanceNeed = ({ totalForms, formPosition, updateFormPosition, defaultValue, setNeed, goBack }) => {
    const [value, setValue] = useState(defaultValue || "")
    const [error, setError] = useState(false)

    const handleContinue = () => {
        if (value) {
            setNeed(value)
        }
        else {
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 2000)
        }
    }
    return (
        <NewRequestLayout
            icon={checkListIcon}
            description="Tell us the type of profession you need."
            totalForms={totalForms} currentForm={formPosition}
            handleBackClick={goBack}
            handleContinueClick={handleContinue}
        >
            <div className="relative">
                <p className="text-versich-dark-blue text-lg font-semibold pb-3">What do you need?</p>
                <RadioOptions
                    actionAsync={allAssistanceNeedAsync}
                    updateFormPosition={updateFormPosition}
                    value={value}
                    setValue={setValue}
                    error={error}
                />
            </div>
        </NewRequestLayout>
    )
}
export default AssistanceNeed
