import { useState } from "react"
import { useLocation, Link } from "react-router-dom"
import { useFormContext } from "react-hook-form"
import { GiTie } from "react-icons/gi"
import { GiBriefcase } from "react-icons/gi"
import { FaCheckCircle } from "react-icons/fa"
import { FaRegCheckCircle } from "react-icons/fa"

import Meta from "../../../components/Meta"
import ConfirmButton from "../../../components/Buttons/ConfirmButton"
import { CLIENT, FREELANCER, SS_ACCOUNT_TYPE, loginPath } from "../../../assets/constants"

const AccountSelection = ({ onSubmit }) => {
    const [selectedOption, setSelectedOption] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    const { register, handleSubmit } = useFormContext()
    const handleAccountSelectionSubmit = async () => {
        if (selectedOption === "") {
            return setErrorMsg("Please select an account type")
        }
        setLoading(true)
        sessionStorage.setItem(SS_ACCOUNT_TYPE.key, selectedOption)
        await new Promise((resolve) => setTimeout(resolve, 500)) // 0.5s delay
        onSubmit({ accountType: selectedOption })
    }

    const location = useLocation()
    return (
        <div className="overflow-hidden flex flex-col justify-center bg-versich-primary-bg items-center">
            <Meta title="Account Selection" description="Account type selection" />
            <div className="bg-gradient-to-t from-versich-blue/5 to-versich-primary-bg to-15% w-full">
                <h2 className="text-2xl sm:text-3xl leading-normal text-center text-versich-dark-blue font-medium px-12 max-[400px]:px-6 py-10 sm:py-16">Join as a {CLIENT} or {FREELANCER}</h2>
            </div>
            <div className="w-full bg-white py-10 sm:py-16 flex justify-center">
                <form className="px-12 max-[400px]:px-6 mx-0 max-w-[700px]" onSubmit={handleSubmit(handleAccountSelectionSubmit)}>
                    <div className="flex flex-col smaller:flex-row gap-8 sm:gap-x-12">
                        <label
                            onClick={() => { setErrorMsg(""); setSelectedOption(CLIENT) }}
                            className={`relative min-h-40 flex flex-col justify-center items-center gap-y-2 p-6 max-sm:px-4 border-2 hover:border-versich-blue/60 rounded-lg cursor-pointer transition-colors group ${selectedOption === CLIENT
                                ? "!border-versich-blue bg-versich-blue/5"
                                : "border-transparent shadow-selection"
                                }`}
                        >
                            <div className="absolute top-[-10px] rounded-full bg-white">
                                {selectedOption === CLIENT
                                    ? <FaCheckCircle className="text-versich-blue text-xl " />
                                    : <FaRegCheckCircle className="text-versich-light-gray group-hover:fill-versich-blue transition-colors text-xl" />
                                }
                            </div>
                            <GiBriefcase className="text-5xl text-versich-dark-blue" />
                            <p className="text-versich-dark-blue font-medium text-sm sm:text-base max-w-52">
                                <input className="appearance-none" type="radio" value={CLIENT} {...register("selection")} />
                                I’m a client, looking for freelancers or consultants
                            </p>
                        </label>
                        <label
                            onClick={() => { setErrorMsg(""); setSelectedOption(FREELANCER) }}
                            className={`relative min-h-40 flex flex-col justify-center items-center gap-y-2 p-6 max-sm:px-4 border-2 hover:border-versich-blue/60 rounded-lg cursor-pointer transition-colors group ${selectedOption === FREELANCER
                                ? "!border-versich-blue bg-versich-blue/5"
                                : "border-transparent shadow-[0px_0px_12px_6px_rgb(0,0,0,0.05)]"
                                }`}
                        >
                            <div className="absolute top-[-10px] rounded-full bg-white">
                                {selectedOption === FREELANCER
                                    ? <FaCheckCircle className="text-versich-blue text-xl " />
                                    : <FaRegCheckCircle className="text-versich-light-gray group-hover:fill-versich-blue transition-colors text-xl" />
                                }
                            </div>
                            <GiTie className="text-5xl text-versich-dark-blue" />
                            <p className="text-versich-dark-blue font-medium text-sm sm:text-base max-w-52">
                                <input className="appearance-none" type="radio" value={FREELANCER} {...register("selection")} />
                                I’m a freelancer or independent consultant, looking for work
                            </p>
                        </label>
                    </div>
                    <div className="w-full mt-8 space-y-2 relative">
                        <ConfirmButton type="submit" loading={loading}>{
                            selectedOption === CLIENT
                                ? "Join as a Client"
                                : selectedOption === FREELANCER
                                    ? "Join as a Freelancer"
                                    : "Join"
                        }</ConfirmButton>
                        <p className="text-start text-red-500">{errorMsg}</p>
                    </div>
                    <p className="text-sm mt-4">
                        Already have an account? <Link className="text-versich-blue hover:text-versich-blue-hover hover:underline" to={loginPath + location.search}>Log in</Link>
                    </p>
                </form>
            </div>
        </div>
    )
}
export default AccountSelection
