
const UnderDevelopment = () => {
    return (
        <div className="bg-white md:col-span-2 xl:col-span-3 shadow-card p-10 sm:p-12 border border-versich-lighter-blue/10 rounded-xl" id="underDevelopment" data-clearance={50}>
            <h2 className="text-versich-lighter-blue font-bold text-2xl mb-10">More Features Coming Soon</h2>
            <div className="flex justify-center p-10">
                <img className="w-[250px] h-[250px]" src="https://cdn-icons-png.flaticon.com/512/10438/10438164.png" alt="work under progress" />
            </div>
            <p className="max-w-[820px] mx-auto">We are currently in the process of developing this page and there will be more features coming soon. For further information, please feel free to <a className="text-versich-blue underline underline-offset-2" href="mailto:Hello@versimarket.com">contact us</a></p>
        </div>
    )
}
export default UnderDevelopment