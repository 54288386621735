import { MdEmail, MdLocationCity } from "react-icons/md"
import { PiLinkBold, PiPhoneCallFill } from "react-icons/pi"

import DashboardConfirmBtn from "../Buttons/DashboardConfirmBtn"
import { openDialog } from "../DialogBox"
import { contactFreelancerDialog } from "../../assets/constants"

const FreelancerCard = ({ className, user, contactExpertClick }) => {
    return (
        <div className={"flex flex-col lg:flex-row gap-y-5 gap-x-10 justify-between p-6 sm:p-8 py-6 border rounded-t-xl " + (className ? className : "")}>
            <div className="flex-1">
                <p className="text-versich-dark-blue text-lg lg:text-xl font-semibold mb-4">{user.companyName ? user.companyName : user.name}</p>
                {/* <p className="text-sm text-versich-label mb-5">{user.profileOverview}</p> */}
                {user.services && (<>
                    <p className="text-sm text-versich-light-blue mb-2.5">Skills</p>
                    <div className="flex flex-wrap flex-col smallest:flex-row gap-3">
                        {user.services.map(service => (
                            <div className="bg-anti-flash-white text-versich-darktext-color text-xs md:text-sm py-1.5 px-2.5 border border-versich-border/40 rounded-2xl" key={service.uid}>{service.title}</div>
                        ))}
                    </div>
                </>)}
            </div>
            <div className="sm:max-lg:grid xl:grid grid-cols-2 gap-4 xl:gap-x-8 min-w-[240px] py-4">
                <div>
                    <p className="text-sm text-versich-label mb-1">Email</p>
                    <a className="flex items-center gap-x-1 mb-3 group" href={`mailto:${user.email}`}>
                        <MdEmail className="fill-versich-light-blue group-hover:fill-versich-blue-hover" />
                        <span className="text-versich-light-blue group-hover:text-versich-blue-hover text-sm overflow-auto">{user.email}</span>
                    </a>
                </div>
                {user.phone &&
                    <div>
                        <p className="text-sm text-versich-label mb-1">Phone number</p>
                        <a className="flex items-center gap-x-1 mb-3 group" href={`tel:${user.phone.code + user.phone.number}`}>
                            <PiPhoneCallFill className="fill-versich-light-blue group-hover:fill-versich-blue-hover" />
                            <span className="text-versich-light-blue group-hover:text-versich-blue-hover text-sm">{user.phone.code} {user.phone.number}</span>
                        </a>
                    </div>
                }
                {user.country &&
                    <div>
                        <p className="text-sm text-versich-label mb-1">Country</p>
                        <div className="flex items-center gap-x-1 mb-3 group">
                            <MdLocationCity className="fill-versich-label" />
                            <span className="text-versich-label text-sm">{user.country.name}</span>
                        </div>
                    </div>
                }
                {user.website &&
                    <div>
                        <p className="text-sm text-versich-label mb-1">Website</p>
                        <a className="flex items-center gap-x-1 mb-3 group" href={user.website} target="_blank" rel="opener">
                            <PiLinkBold className="fill-versich-light-blue group-hover:fill-versich-blue-hover" />
                            <span className="text-versich-light-blue group-hover:text-versich-blue-hover text-sm">{user.website}</span>
                        </a>
                    </div>
                }
                <div className="sm:max-lg:col-span-2 xl:col-span-2 mt-6">
                    <DashboardConfirmBtn onClick={(e) => { contactExpertClick(); openDialog(e) }} dataParentId={contactFreelancerDialog}>Contact this expert</DashboardConfirmBtn>
                </div>
            </div>
        </div >
    )
}
export default FreelancerCard