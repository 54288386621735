import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import apiErrorParser from "../../utils/apiErrorParser"

export const directMessageToFreelancerAsync = createAsyncThunk("account/directMessageToFreelancer",
    async ({ freelancerEmail, message, token }) => {
        try {
            const { data } = await api.directMessageToFreelancer({ freelancerEmail, message, csrfmiddlewaretoken: token })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })
