import ProfileCard from "../components/ProfileCard"
import HelpCard from "../components/HelpCard"
import Requests from "../../../components/Requests/Requests"
import AboutCard from "../components/AboutCard"

const ClientOptions = () => {
    return (<>
        <div className="grid md:grid-cols-5 xl:grid-cols-3 gap-x-6 gap-y-5">
            <div className="md:col-span-3 xl:col-span-2 flex flex-col xl:flex-row gap-x-6 gap-y-5">
                <ProfileCard className="flex-1" />
                <AboutCard className="flex-1" />
            </div>
            <div className="space-y-4 flex-1 md:col-span-2 xl:col-span-1">
                <HelpCard />
            </div>
        </div>
        <Requests />
    </>)
}
export default ClientOptions