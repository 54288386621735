import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import Meta from "../../../../components/Meta"
import RequestCard from "../../../../components/Requests/RequestCard"
import BackButton from "../../../../components/Buttons/BackButton"
import planet from "../../../../assets/images/planet.png"
import DashboardConfirmBtn from "../../../../components/Buttons/DashboardConfirmBtn"
import AddPlus from "../../../../components/svgs/AddPlus"
import { dashboardPath, newRequestPath } from "../../../../assets/constants"
import { allUserRequestAsync } from "../../../../slices/client/service"

const AllRequest = () => {
    const [requests, setRequests] = useState([])

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(allUserRequestAsync())
            .unwrap()
            .then(data => {
                setRequests(data.payload)
            })
            .catch(() => { })
    }, [])

    const navigate = useNavigate()
    const navigateToNewRequest = () => navigate(newRequestPath)
    return (
        <div className="py-10 md:py-14 px-6 md:px-16 lg:px-28 text-start mb-5 lg:mb-12 overflow-hidden bg-versich-primary-bg space-y-8">
            <Meta title="VersiMarket | Request Page" description="Responses page for requests" />
            <div className="bg-white shadow-card rounded-xl">
                <div className="border-b-2 px-3 py-3 md:py-6">
                    <BackButton onClick={() => navigate(dashboardPath)} />
                </div>
                <div className="px-3 py-12 space-y-4">
                    {requests.length > 0 ? (
                        <div className="text-start px-3 space-y-5">
                            <div className="flex gap-4 justify-between max-smaller:items-center pb-3">
                                <p className="text-lg lg:text-xl text-versich-dark-blue font-semibold">Your requests</p>
                                <button className="text-versich-blue hover:text-versich-blue-hover flex items-center gap-1 disabled:cursor-not-allowed disabled:opacity-80 group" onClick={navigateToNewRequest} type="button" title="New request">
                                    <AddPlus className="[&>*]:stroke-versich-blue group-hover:[&>*]:stroke-versich-blue-hover smaller:max-lg:w-[18px] smaller:max-lg:h-[18px] w-6 h-6" />
                                    <span className="max-smaller:hidden">New request</span>
                                </button>
                            </div>
                            {requests.map((data, index) => (
                                <RequestCard data={data} key={index} />
                            ))}
                        </div>
                    ) : (
                        <div>
                            <div className="flex justify-center">
                                <img src={planet} alt="planet" />
                            </div>
                            <div className="space-y-1">
                                <p className="text-center text-base lg:text-lg font-semibold text-versich-dark-blue">There are no active requests</p>
                                <p className="text-center text-xs lg:text-sm text-versich-label">Please create a new request. Need something else?</p>
                            </div>
                            <div className="text-center mt-4">
                                <DashboardConfirmBtn onClick={navigateToNewRequest}>Place new request</DashboardConfirmBtn>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
export default AllRequest
