import { useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import Meta from "../../../../components/Meta"
import FreelancerCard from "../../../../components/Requests/FreelancerCard"
import ContactFreelancerDialog from "../../../../components/Requests/ContactFreelancerDialog"
import { serviceRelatedFreelancersAsync } from "../../../../slices/freelancer"

const RelatedFreelancers = () => {
    const { id } = useParams()
    const [relatedFreelancers, setRelatedFreelancers] = useState([])
    const [recommendedFreelancers, setRecommendedFreelancers] = useState([])
    const [freelancerEmail, setFreelancerEmail] = useState("")
    const [messageSent, setMessageSent] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(serviceRelatedFreelancersAsync(id))
            .unwrap()
            .then(data => {
                setRelatedFreelancers(data.relatedFreelancers)
                setRecommendedFreelancers(data.recommendedFreelancers)
            })
            .catch(() => { })
    }, [])
    return (
        <div className="bg-versich-primary-bg px-6 md:px-16 lg:px-28 py-10">
            <Meta title="Request Confirmation" description="VersiMarket user dashboard" />
            <div className="max_screen_w space-y-8">
                {messageSent &&
                    <div className="bg-cyan-100 shadow-sticker w-full px-4 py-2 sm:px-6 sm:py-4 font-semibold text-start text-versich-dark-blue rounded-xl">
                        Message sent to freelancer.
                    </div>
                }
                <div className="bg-white shadow-card rounded-xl">
                    <div className="px-5 md:px-10 py-6 md:py-12 space-y-4">
                        <div className="text-start space-y-5">
                            <p className="text-xl lg:text-2xl text-versich-dark-blue font-semibold pb-3">Recommended Freelancers</p>
                            {recommendedFreelancers.map(freelancer => (
                                <FreelancerCard user={freelancer} contactExpertClick={() => setFreelancerEmail(freelancer.email)} key={freelancer.email} />
                            ))}
                        </div>
                    </div>
                </div>
                {relatedFreelancers.length > 0 && (<>
                    <div className="text-versich-dark-blue font-semibold text-lg lg:text-xl text-start w-full px-2 py-2 sm:px-4 sm:py-4">
                        Other Related Freelancers
                    </div>
                    <div className="text-start space-y-5">
                        {relatedFreelancers.map(freelancer => (
                            <FreelancerCard className="bg-white border-none shadow-card rounded-xl" user={freelancer} contactExpertClick={() => setFreelancerEmail(freelancer.email)} key={freelancer.email} />
                        ))}
                    </div>
                </>)}
            </div>
            <ContactFreelancerDialog freelancerEmail={freelancerEmail} setMessageSent={setMessageSent} />
        </div>
    )
}
export default RelatedFreelancers