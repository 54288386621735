// Paths
export const homePath = "/"
export const servicesPath = "/services/"
export const dashboardPath = "/dashboard/"
export const editProfilePath = "/dashboard/edit/profile/"
export const editPrimaryServicePath = "/dashboard/edit/primary-service/"
export const editLeadsLocationPath = "/dashboard/edit/leads/"
export const editOtherServicesPath = "/dashboard/edit/other-services/"
export const newRequestPath = "/request/new/"
export const requestConfirmationPath = "/dashboard/request/:id/confirmation/:pid/"
export const requestDetailsPath = "/dashboard/request/:id/details/"
export const allRequestPath = "/dashboard/request/all/"
export const serviceRelatedfreelancerPath = "/dashboard/freelancer/service/:id/"
export const signupPath = "/auth/signup/"
export const loginPath = "/auth/login/"
export const setupPath = "/auth/su/"
export const emailVerificationPath = "/auth/verification/email/"

// Conditional paths based on account type
export const clientPaths = {
    root: "/auth/su/client/",
    profile: "/auth/su/client/profile/",
}

export const freelancerPaths = {
    root: "/auth/su/freelancer/",
    primaryservice: "/auth/su/freelancer/primary-service/",
    leads: "/auth/su/freelancer/leads/",
    profile: "/auth/su/freelancer/profile/",
    otherservices: "/auth/su/freelancer/other-services/"
}

// Accounts
export const CLIENT = "client"
export const FREELANCER = "freelancer"

// States
export const verificationStates = {
    verifying: {
        code: "verifying",
        text: "Verifying"
    },
    verified: {
        code: "verified",
        text: "Verified"
    },
    expired: {
        code: "expired",
        text: "Expired"
    }
}

// Leads location
export const regionCoverageOptions = [{
    value: "worldwide",
    label: "I serve customers worldwide"
}, {
    value: "custom",
    label: "I serve customers within"
}]
export const regionCoverage = Object.fromEntries(regionCoverageOptions.map(coverage => [coverage.value, {
    label: coverage.label,
    value: coverage.value
}]))

export const companySizes = [{
    value: "soletrader",
    label: "Self-employed, Sole trader"
}, {
    value: "2-10",
    label: "2-10"
}, {
    value: "11-50",
    label: "11-50"
}, {
    value: "51-200",
    label: "51-200"
}]

// Cookie keys
export const SS_REMEMBER_ME = "remember_me"
export const SS_AUTH_ERROR = { key: "auth_error", value: "" }
export const SS_VERIFICATION_EMAIL = { key: "verification_email", value: "resend" }
export const SS_VERIFICATION_STATUS = { key: "verification_status", value: "pending" }
export const SS_ACCOUNT_TYPE = { key: "account_type", value: null }
export const SS_FINANCETRANSFORMATION_OTHER = "financeTransformation_other"
export const COOKIE_NEW_REQUEST_WDAD = "new_service_request_WDAD"
export const COOKIE_NEW_REQUEST_MA = "new_service_request_MA"
export const COOKIE_NEW_REQUEST_DAA_BU = "new_service_request_DAA_BU"
export const COOKIE_NEW_REQUEST_DAA_PE = "new_service_request_DAA_PE"
export const COOKIE_NEW_REQUEST_SD = "new_service_request_SD"
export const COOKIE_NEW_REQUEST_FT_FPAR = "new_service_request_FT_FPAR"
export const COOKIE_NEW_REQUEST_FT_FRAA = "new_service_request_FT_FRAA"
export const COOKIE_NEW_REQUEST_FT_EN = "new_service_request_FT_EN"
export const COOKIE_NEW_REQUEST_FT_SA = "new_service_request_FT_SA"
export const COOKIE_NEW_REQUEST_FT_DT = "new_service_request_FT_DT"
export const COOKIE_NEW_REQUEST_FT = "new_service_request_FT"
export const COOKIE_NEW_REQUEST_MLAI = "new_service_request_MLAI"

// Class
export const EMPTY = "empty"
export const HIGHPARENT = "highlight-parent"

// Id
export const contactFreelancerDialog = "contactfreelancer-dialog"

// Values
export const TOASTTIMER = 3500
export const TOASTTYPE_INFO = "info"
export const TOASTTYPE_SUCCESS = "success"
export const TOASTTYPE_WARN = "warn"
export const TOASTTYPE_ERROR = "error"