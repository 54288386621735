import { useEffect } from "react"

import documentEditIcon from "../../../../../assets/icons/request/document-edit.png"
import NewRequestLayout from "../NewRequestLayout"

const Description = ({ totalForms, formPosition, updateFormPosition, description, setDescription, error, goBack }) => {
    useEffect(() => {
        updateFormPosition()
    }, [])
    return (
        <NewRequestLayout
            icon={documentEditIcon}
            description="Explain what you need so we get you experts to meet your needs"
            totalForms={totalForms} currentForm={formPosition}
            handleBackClick={goBack}
        >
            <div className="relative">
                <p className="text-versich-dark-blue text-lg font-semibold pb-5">Describe what you need, The more detailed, the better</p>
                <textarea
                    className={"w-full min-h-40 max-h-80 mb-5 px-3 py-2 border outline-none rounded-md " + (error ? "border-red-500" : "border-versich-border")}
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                />
                {error && <p className="text-red-500 text-start text-sm mb-3">Please enter a value</p>}
            </div>
        </NewRequestLayout>
    )
}
export default Description
