import image1 from "../assets/images/web-design-and-development.jpg"
import icon1 from "./icons/services/web-design-and-development.png"
import image2 from "../assets/images/mobile-app.jpg"
import icon2 from "./icons/services/mobile-app.png"
import image3 from "../assets/images/data-and-analytics.jpg"
import icon3 from "./icons/services/data-and-analytics.png"
import image4 from "../assets/images/software-development.jpg"
import icon4 from "./icons/services/software-development.png"
import image5 from "../assets/images/finance-transformation.jpg"
import icon5 from "./icons/services/finance-transformation.png"
import image6 from "../assets/images/machine-learning-and-ai.jpg"
import icon6 from "./icons/services/machine-learning-and-ai.png"

/**
 * An array of service offerings provided by the company.
 * Each service offering is represented as an object with the following properties:
 * - id: a unique identifier for the service offering
 * - image: the path to an image for the service offering
 * - icon: the path to an icon image for the service offering
 * - name: the name of the service offering
 * - link: a URL link to more information about the service offering
 */
const ServicesList = [
    {
        id: 1,
        image: image1,
        icon: icon1,
        name: "Web Design and Development",
        uid: "web-design-and-development",
    },
    {
        id: 2,
        image: image2,
        icon: icon2,
        name: "Mobile App",
        uid: "mobile-app",
    },
    {
        id: 3,
        image: image3,
        icon: icon3,
        name: "Data and Analytics",
        uid: "data-and-analytics",
    },
    {
        id: 4,
        image: image4,
        icon: icon4,
        name: "Software Development",
        uid: "software-development",
    },
    {
        id: 5,
        image: image5,
        icon: icon5,
        name: "Finance Transformation",
        uid: "finance-transformation",
        subServices: [{
            title: "FP & A Revolution",
            uid: "fp-and-a-revolution"
        }, {
            title: "Financial Reporting and Advanced Analytics",
            uid: "financial-reporting-and-advanced-analytics"
        }, {
            title: "ERP / EPM / System Implementation",
            uid: "enterprise"
        }, {
            title: "Systems Administration",
            uid: "system-administration"
        }, {
            title: "Digital Transformation",
            uid: "digital-transformation"
        }]
    },
    {
        id: 6,
        image: image6,
        icon: icon6,
        name: "Machine Learning and AI",
        uid: "machine-learning-and-ai",
    },
]
export default ServicesList
