
const NewRequestLayout = ({ children, icon, description, totalForms, currentForm, handleBackClick, handleContinueClick }) => {
    return (
        <div className="max_screen_w bg-white grid grid-cols-1 md:grid-cols-3 gap-y-3 shadow-form rounded-md overflow-hidden">
            <div className="bg-oxford-blue text-white inline-flex flex-col sm404:max-md:items-start items-center sm404:max-md:flex-row gap-x-4 gap-y-8 md:w-[300px] px-8 md:px-12 py-6 md:py-20">
                <img className="w-16 h-16 md:w-24 md:h-24" src={icon} alt="office" />
                {description && <p className="sm404:max-md:text-start max-md:text-sm">{description}</p>}
            </div>
            <div className="col-span-2 flex justify-center text-start px-4 md:py-6">
                <div className="bg-white flex-1 flex flex-col gap-3 py-6 rounded-md px-4 max-w-[568px]">
                    {totalForms &&
                        <div className="bg-[#6666661a] w-full h-1 rounded-full overflow-hidden my-3">
                            <div
                                className="bg-[#1D88ED] w-0 h-full rounded-full transition-all duration-700"
                                style={{
                                    width: `${(currentForm / totalForms) * 100}%`,
                                }}
                            ></div>
                        </div>
                    }
                    {children}
                    <hr className="!mb-3 border-1 border-gray-200 rounded-xl" />
                    <div className="w-full flex justify-between">
                        <button className="bg-slate-100 hover:bg-slate-200 text-gray-900 border border-versich-border/20 rounded-md py-2 lg:py-2.5 px-4 lg:px-6" type="button" onClick={handleBackClick}>Back</button>
                        {handleContinueClick
                            ? <button className="bg-versich-blue hover:bg-versich-blue-hover text-white rounded-md py-2 lg:py-2.5 px-4 lg:px-6" onClick={handleContinueClick} type="button">Continue</button>
                            : <button className="bg-versich-blue hover:bg-versich-blue-hover text-white rounded-md py-2 lg:py-2.5 px-4 lg:px-6" type="submit">Submit</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NewRequestLayout 