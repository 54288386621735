import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import planet from "../../assets/images/planet.png"
import RequestCard from "./RequestCard"
import DashboardConfirmBtn from "../Buttons/DashboardConfirmBtn"
import AddPlus from "../svgs/AddPlus"
import { allRequestPath, newRequestPath } from "../../assets/constants"
import { latestUserRequestAsync } from "../../slices/client/service"

const Requests = () => {
    const [requests, setRequests] = useState([])
    const [hasMoreRequests, setHasMoreRequests] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(latestUserRequestAsync())
            .unwrap()
            .then(data => {
                setRequests(data.payload.requests)
                setHasMoreRequests(data.payload.hasMore)
            })
            .catch(() => { })
    }, [])

    const navigate = useNavigate()
    const navigateToNewRequest = () => navigate(newRequestPath)
    return (
        <div className="bg-white shadow-card rounded-xl">
            <div className="px-3 py-6 md:py-12 space-y-4">
                {requests.length > 0 ? (
                    <div className="text-start px-3 space-y-5">
                        <div className="flex gap-4 justify-between max-smaller:items-center pb-3">
                            <p className="text-lg lg:text-xl text-versich-dark-blue font-semibold">Your requests</p>
                            <button className="text-versich-blue hover:text-versich-blue-hover flex items-center gap-1 disabled:cursor-not-allowed disabled:opacity-80 group" onClick={navigateToNewRequest} type="button" title="New request">
                                <AddPlus className="[&>*]:stroke-versich-blue group-hover:[&>*]:stroke-versich-blue-hover smaller:max-lg:w-[18px] smaller:max-lg:h-[18px] w-6 h-6" />
                                <span className="max-smaller:hidden">New request</span>
                            </button>
                        </div>
                        {requests.map((data, index) => (
                            <RequestCard data={data} key={index} />
                        ))}
                        {hasMoreRequests &&
                            <div className="text-center mt-4">
                                <DashboardConfirmBtn onClick={() => navigate(allRequestPath)}>More requests</DashboardConfirmBtn>
                            </div>
                        }
                    </div>
                ) : (
                    <div>
                        <div className="flex justify-center">
                            <img src={planet} alt="planet" />
                        </div>
                        <div className="space-y-1">
                            <p className="text-center text-base lg:text-lg font-semibold text-versich-dark-blue">There are no active requests</p>
                            <p className="text-center text-xs lg:text-sm text-versich-label">Please create a new request. Need something else?</p>
                        </div>
                        <div className="text-center mt-4">
                            <DashboardConfirmBtn onClick={navigateToNewRequest}>Place new request</DashboardConfirmBtn>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
export default Requests
