import { useEffect, useState, useRef } from "react"
import { useDispatch } from "react-redux"
import { MdClose } from "react-icons/md"
import { IoIosArrowDown } from "react-icons/io"

import { otherRemainingServicesAsync } from "../../slices/freelancer/otherService"

const ComboServicesInputField = ({ onSelect }) => {
    const [otherOptions, setOtherOptions] = useState([])
    const [filteredOptions, setFilteredOptions] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])
    const [search, setSearch] = useState("")
    const [isOpen, setIsOpen] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(otherRemainingServicesAsync())
            .unwrap()
            .then(data => {
                setOtherOptions(data.remainingServices)
                setFilteredOptions(data.remainingServices)
                setSelectedOptions(data.otherServices)
            })
            .catch(() => { })
    }, [])

    // Store selected options
    useEffect(() => {
        onSelect(selectedOptions.map(option => option.uid))
    }, [selectedOptions])

    // Search
    const handleSearch = (event) => {
        const searchTerm = event.target.value
        setSearch(searchTerm)

        // Filtering options from all options
        let filtered = otherOptions.filter((option) => option.title.toLowerCase().includes(searchTerm.toLowerCase()))

        // Filtering filtered options from selected options
        filtered = filtered.filter(option => selectedOptions.includes(option) === false)
        setFilteredOptions(filtered)
    }

    // Select option
    const handleOptionSelection = (option) => {
        setSelectedOptions((prevSelectedOptions) => [
            ...prevSelectedOptions,
            option,
        ])
        setFilteredOptions((prevFilteredOptions) =>
            prevFilteredOptions.filter((o) => o !== option)
        )
    }

    // Remove option
    const removeSelection = (option) => {
        setSelectedOptions((prevSelectedOptions) =>
            prevSelectedOptions.filter((o) => o !== option)
        )
        setFilteredOptions((prevFilteredOptions) => [
            ...prevFilteredOptions,
            option,
        ])
    }

    // Handle Outside click
    const comboInputRef = useRef(null)
    useEffect(() => {
        const handleClickOutside = (event) => {
            comboInputRef.current && !comboInputRef.current.contains(event.target)
                && setIsOpen(false)
        }
        document.addEventListener("click", handleClickOutside)
        return () => {
            document.removeEventListener("click", handleClickOutside)
        }
    }, [])
    return (
        <div className="flex flex-col gap-y-4 mt-2" ref={comboInputRef}>
            {selectedOptions.length > 0 &&
                <div className="flex gap-x-4 flex-wrap gap-y-2">
                    {selectedOptions.map((option) => (
                        <button className="flex items-center gap-x-2 hover:bg-versich-blue-hover transition-all ease-out duration-600 py-2 rounded-2xl text-sm text-white px-6 bg-versich-blue"
                            onClick={() => removeSelection(option)} key={option.uid}
                        >
                            {option.title}
                            <MdClose />
                        </button>
                    ))}
                </div>
            }
            <div className="relative z-50">
                <input
                    className="relative w-full h-10 rounded-md border-[1px] border-versich-border px-3"
                    name="selectedServices"
                    type="text"
                    value={search}
                    onChange={handleSearch}
                    placeholder="Search Services..."
                    onClick={() => setIsOpen(true)}
                />
                <button className={`absolute top-3 right-4 text-xl transition-transform ease-in duration-600 transform ${isOpen ? "rotate-180" : "rotate-0"}`} onClick={() => setIsOpen(!isOpen)}>
                    {isOpen ? <IoIosArrowDown /> : <IoIosArrowDown />}
                </button>
                {isOpen &&
                    <div className="absolute bg-white mt-2 overflow-y-auto max-h-48 w-full rounded-md border-[1px] border-versich-border">
                        {filteredOptions.map((option) => (
                            <ul key={option.uid}>
                                <li className="p-2 border-b border-gray-200" onClick={() => handleOptionSelection(option)}>{option.title}</li>
                            </ul>
                        ))}
                    </div>
                }
            </div>
        </div>
    )
}
export default ComboServicesInputField
