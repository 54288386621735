import { useSelector } from "react-redux"

import DashboardLayout from "./components/DashboardLayout"
import UserSpecificOptions from "./components/UserSpecificOptions"

const Dashboard = () => {
    const { user } = useSelector((state) => state.auth)
    return (
        <DashboardLayout>
            <UserSpecificOptions accountType={user.account_type} />
        </DashboardLayout>
    )
}
export default Dashboard
