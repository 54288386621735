import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import { getUser } from "../../actions/auth"
import apiErrorParser from "../../utils/apiErrorParser"

export const freelancerCreateAboutAsync = createAsyncThunk("account/freelancerCreateAbout",
    async ({ companyName, address, phonecode, phonenumber, website, companysize, salesteam, socialmedia, token }, { dispatch }) => {
        try {
            const { data } = await api.freelancerAboutCreate({
                company_name: companyName, address, phonecode, phonenumber, website, companysize, salesteam, socialmedia, csrfmiddlewaretoken: token
            })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })

export const freelancerUpdateAboutAsync = createAsyncThunk("account/freelancerUpdateAbout",
    async ({ companyName, address, phonecode, phonenumber, website, companysize, salesteam, socialmedia, token }, { dispatch }) => {
        try {
            const { data } = await api.freelancerAboutUpdate({
                company_name: companyName, address, phonecode, phonenumber, website, companysize, salesteam, socialmedia, csrfmiddlewaretoken: token
            })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })