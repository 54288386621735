import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import { getUser } from "../../actions/auth"
import apiErrorParser from "../../utils/apiErrorParser"

export const freelancerLeadsLocationCreateAsync = createAsyncThunk("account/freelancerLeadsLocationCreate",
    async ({ coverage, countryiso3, statecode, token }, { dispatch }) => {
        try {
            const { data } = await api.freelancerLeadsLocationCreate({ coverage, countryiso3, statecode, csrfmiddlewaretoken: token })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })


export const freelancerLeadsLocationUpdateAsync = createAsyncThunk("account/freelancerLeadsLocationUpdate",
    async ({ coverage, countryiso3, statecode, token }, { dispatch }) => {
        try {
            const { data } = await api.freelancerLeadsLocationUpdate({ coverage, countryiso3, statecode, csrfmiddlewaretoken: token })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })