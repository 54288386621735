import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation, Link } from "react-router-dom"
import { useForm, FormProvider } from "react-hook-form"

import Meta from "../../components/Meta"
import CSRFTokenField from "../../components/Inputs/CSRFTokenField"
import InputTextField from "../../components/Inputs/InputTextField"
import ConfirmButton from "../../components/Buttons/ConfirmButton"
import SocialAccounts from "./socialAccounts/SocialAccounts"
import { SS_AUTH_ERROR, SS_REMEMBER_ME, signupPath } from "../../assets/constants"
import { loginUser } from "../../actions/auth"

const Login = () => {
    const methods = useForm()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [csrfToken, setCsrfToken] = useState("")
    const [rememberMe, setRememberMe] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // OAuth2 authentication error
    useEffect(() => {
        const authError = window.sessionStorage.getItem(SS_AUTH_ERROR.key)
        if (authError) {
            setErrorMsg(authError)
            window.sessionStorage.removeItem(SS_AUTH_ERROR.key)
        }
        const qEmail = new URLSearchParams(location.search).get("email")
        if (qEmail) {
            setEmail(qEmail)
        }
        else {
            const rememberMeData = window.sessionStorage.getItem(SS_REMEMBER_ME)
            if (!rememberMeData) {
                return
            }
            const { email: rEmail, password: rPassword } = JSON.parse(rememberMeData)
            setEmail(rEmail)
            try {
                setPassword(window.atob(rPassword))
            }
            catch (error) {
                setPassword(rPassword)
            }
            setRememberMe(true)
        }
    }, [])

    const dispatch = useDispatch()
    const handleLogin = async (data) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid) {
                return
            }
            setLoading(true)
            const result = await dispatch(loginUser(email, password, csrfToken))
            if (result.status !== "success") {
                return
            }
            if (rememberMe) {
                window.sessionStorage.setItem(SS_REMEMBER_ME, JSON.stringify({ email, password: window.btoa(password) }))
            }
            else window.sessionStorage.removeItem(SS_REMEMBER_ME)
            methods.reset()
        }
        catch (error) {
            if (error.response && error.response.status) {
                setErrorMsg(error.message)
                return error
            }
            setErrorMsg(error.message)
        }
        finally {
            setLoading(false)
        }
    }

    const location = useLocation()
    return (
        <div>
            <Meta title="Login" description="Login to your account" />
            <div className="py-10 md:py-14 px-3 overflow-hidden flex justify-center bg-versich-primary-bg items-center">
                <div className="w-full bg-white shadow-form my-6 py-5 md:py-10 px-5 md:px-10 max-w-[580px] rounded-lg">
                    <h2 className="text-2xl lg:text-3xl leading-normal text-left mb-5 text-versich-darktext-color font-medium">Log in</h2>
                    <FormProvider {...methods}>
                        <form noValidate onSubmit={methods.handleSubmit(handleLogin)}>
                            <div className="space-y-6 md:space-y-7 text-start">
                                <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                                <InputTextField
                                    name="email"
                                    inputType="text"
                                    label="Email address"
                                    placeholder="Enter your email"
                                    rules={{ required: "Field is required" }}
                                    onChange={(e) => setEmail(e.target.value)}
                                    defaultValue={email}
                                />
                                <InputTextField
                                    name="password"
                                    inputType="password"
                                    label="Password"
                                    placeholder="************"
                                    rules={{ required: "Password is required" }}
                                    onChange={(e) => setPassword(e.target.value)}
                                    defaultValue={password}
                                />
                                {errorMsg && <div className="text-sm text-red-500 text-left">{errorMsg}</div>}
                                <div className="flex items-center">
                                    <input type="checkbox" className="mr-2" id="remember" onChange={() => setRememberMe(prev => !prev)} checked={rememberMe} />
                                    <label htmlFor="remember" className="text-sm text-versich-label hover:text-versich-blue-hover">Remember me</label>
                                </div>
                                <ConfirmButton type="submit" loading={loading}>{loading ? "Logging in..." : "Log In"}</ConfirmButton>
                                {/* <Link className="underline block text-sm hover:text-versich-blue-hover text-center" to="#">Forget Password</Link> */}
                                <p className="text-sm text-center">
                                    Don't have an account?{" "}
                                    <Link className="text-versich-blue hover:text-versich-blue-hover hover:underline" to={signupPath + location.search}>Sign up</Link>
                                </p>
                                <SocialAccounts google={{ url: process.env.REACT_APP_API_GOOGLE_OAUTH2_URL, text: "Continue with Google" }} />
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>
    )
}
export default Login
