import { useSelector } from "react-redux"
import { MdBusiness, MdClose, MdLocationPin, MdOutlineEmail } from "react-icons/md"
import { CiLocationOn, CiPhone } from "react-icons/ci"
import { PiLink, PiLinkedinLogo } from "react-icons/pi"
import { BsCheckLg } from "react-icons/bs"
import { GoPeople } from "react-icons/go"
import { FaUserTie } from "react-icons/fa"

const AboutCard = ({ className }) => {
    const { user } = useSelector((state) => state.auth)
    return (
        <div className={className}>
            <div className="bg-white shadow-card px-4 py-4 sm:px-6 sm:py-6 rounded-xl space-y-4 text-start">
                <div className="text-start text-lg lg:text-xl font-semibold text-versich-dark-blue">About</div>
                <div>
                    <p className="text-sm text-versich-label mb-1">Email</p>
                    <div className="flex items-center gap-x-1 mb-3">
                        <MdOutlineEmail className="fill-versich-label" />
                        <p className="text-sm">{user.email}</p>
                    </div>
                </div>
                {user.about && (<>
                    <div>
                        <p className="text-sm text-versich-label mb-1">Phone number</p>
                        <div className="flex items-center gap-x-1 mb-3">
                            <CiPhone />
                            <p className="text-sm">{user.about.phone.code} {user.about.phone.number}</p>
                        </div>
                    </div>
                    {user.about.companyName &&
                        <div>
                            <p className="text-sm text-versich-label mb-1">Company name</p>
                            <div className="flex items-center gap-x-1 mb-3">
                                <MdBusiness className="fill-versich-label" />
                                <p className="text-sm">{user.about.companyName}</p>
                            </div>
                        </div>
                    }
                    {user.about.address &&
                        <div>
                            <p className="text-sm text-versich-label mb-1">Address</p>
                            <div className="flex items-start gap-x-1 mb-3">
                                <MdLocationPin className="fill-versich-label" />
                                <p className="text-sm">{user.about.address}</p>
                            </div>
                        </div>
                    }
                    {user.about.country &&
                        <div>
                            <p className="text-sm text-versich-label mb-1">Country</p>
                            <div className="flex items-center gap-x-1 mb-3">
                                <CiLocationOn />
                                <p className="text-sm">{user.about.country.name}</p>
                            </div>
                        </div>
                    }
                    {user.about.linkedinUrl &&
                        <div>
                            <p className="text-sm text-versich-label mb-1">Linkedin profile</p>
                            <div className="flex items-center gap-x-1 mb-3">
                                <PiLinkedinLogo className="fill-versich-label" />
                                <p className="text-sm">{user.about.linkedinUrl}</p>
                            </div>
                        </div>
                    }
                    {user.about.website &&
                        <div>
                            <p className="text-sm text-versich-label mb-1">Website</p>
                            <div className="flex items-center gap-x-1 mb-3">
                                <PiLink />
                                <p className="text-sm">{user.about.website}</p>
                            </div>
                        </div>
                    }
                    {user.about.companySize &&
                        <div>
                            <p className="text-sm text-versich-label mb-1">Company size</p>
                            <div className="flex items-center gap-x-1 mb-3">
                                <GoPeople />
                                <p className="text-sm">{user.about.companySize.title}{`${user.about.companySize.uid !== "soletrader" ? " employees" : ""}`}</p>
                            </div>
                        </div>
                    }
                    {user.about.salesTeam !== undefined &&
                        <div>
                            <p className="text-sm text-versich-label mb-1">Has sales team?</p>
                            <div className="flex items-center gap-x-1 mb-3">
                                <FaUserTie className="fill-versich-label" />
                                <p className="text-sm">
                                    {user.about.salesTeam ? <BsCheckLg className="fill-green-600" title="Yes" /> : <MdClose className="fill-red-600" title="No" />}
                                </p>
                            </div>
                        </div>
                    }
                    {user.about.socialMedia !== undefined &&
                        <div>
                            <p className="text-sm text-versich-label mb-1">Has social media?</p>
                            <div className="flex items-center gap-x-1 mb-3">
                                <FaUserTie className="fill-versich-label" />
                                <p className="text-sm">
                                    {user.about.socialMedia ? <BsCheckLg className="fill-green-600" title="Yes" /> : <MdClose className="fill-red-600" title="No" />}
                                </p>
                            </div>
                        </div>
                    }
                </>)}
            </div>
        </div>
    )
}
export default AboutCard