import * as api from "../../api"
import apiErrorParser from "../../utils/apiErrorParser"

export const getMobileProjectTypes = (params) => async () => {
    try {
        const { data } = await api.mobileProjectTypesMA(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
}

export const getMobileAppPlatforms = (params) => async () => {
    try {
        const { data } = await api.mobileAppPlatformsMA(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
}

export const getMobileProjectNeeds = (params) => async () => {
    try {
        const { data } = await api.mobileProjectNeedsMA(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
}