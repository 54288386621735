import { useSelector } from "react-redux"
import { useLocation, Navigate, Outlet } from "react-router-dom"

import ErrorPage from "../../ErrorPage/ErrorPage"
import { FREELANCER, freelancerPaths } from "../../../assets/constants"

const FreelancerSetupRoutes = () => {
    const { user } = useSelector(state => state.auth)
    const location = useLocation()
    if (user.account_type !== FREELANCER) {
        return <ErrorPage status={403} />
    }
    const route = getProfileLink(user)
    if (route) {
        if (location.pathname !== route) {
            return <Navigate to={route} replace />
        }
        return <Outlet />
    }
    return <ErrorPage status={404} />
}
const getProfileLink = (user) => {
    if (!user.primaryService) {
        return freelancerPaths.primaryservice
    }
    else if (!user.leadsLocation) {
        return freelancerPaths.leads
    }
    else if (!user.about) {
        return freelancerPaths.profile
    }
    else if (!user.otherService) {
        return freelancerPaths.otherservices
    }
}

export default FreelancerSetupRoutes