import { useParams, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { BeatLoader } from "react-spinners"
import { formatDistanceToNow } from "date-fns"
import { MdCreate, MdOutlineUpdate, MdCalendarMonth } from "react-icons/md"
import { GrMoney } from "react-icons/gr"
import { GiMoneyStack } from "react-icons/gi"

import ErrorPage from "../../../../components/ErrorPage/ErrorPage"
import Meta from "../../../../components/Meta"
import BackButton from "../../../../components/Buttons/BackButton"
import { allRequestPath, serviceRelatedfreelancerPath } from "../../../../assets/constants"
import { fetchRequestAction } from "./utils"
import DashboardConfirmBtn from "../../../../components/Buttons/DashboardConfirmBtn"

const RequestDetails = () => {
    const { id } = useParams()
    const [request, setRequest] = useState(undefined)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        const actionAsync = fetchRequestAction(id)
        dispatch(actionAsync(id))
            .unwrap()
            .then(data => {
                setRequest(data.payload)
            })
            .catch(() => setRequest(null))
    }, [])

    const viewExpert = (serviceId) => {
        const relaventExpertsPath = serviceRelatedfreelancerPath.replace(":id", serviceId)
        navigate(relaventExpertsPath)
    }
    return (
        request === null
            ? <ErrorPage status={404} />
            : <div className="py-10 md:py-14 px-6 md:px-16 lg:px-28 text-start mb-5 lg:mb-12 overflow-hidden bg-versich-primary-bg space-y-8">
                <Meta title={request ? request.title : "VersiMarket | Request Details Page"} description="Responses page for requests" />
                <div className="bg-white shadow-card rounded-xl">
                    <div className="border-b-[3px] border-versich-blue/5 p-3 md:p-6">
                        <BackButton onClick={() => navigate(allRequestPath)} />
                    </div>
                    {request
                        ? <div className="p-5 md:p-10 space-y-4">
                            <div className="text-start space-y-5">
                                <div className="flex max-md:flex-col mb-1.5 md:mb-8">
                                    <div className="flex-1 p-3">
                                        <p className="text-xl lg:text-2xl text-versich-dark-blue font-semibold pb-3">{request.title}</p>
                                        <div className="text-xs md:text-sm flex flex-col smaller:flex-row gap-y-3 gap-x-4">
                                            <div className="inline-flex items-center gap-1 md:gap-1.5"><MdCreate /> Posted {formatDistanceToNow(request.createdAt, { addSuffix: true })}</div>
                                            <div className="inline-flex items-center gap-1 md:gap-1.5"><MdOutlineUpdate /> Updated {formatDistanceToNow(request.updatedAt, { addSuffix: true })}</div>
                                        </div>
                                    </div>
                                    <div className="pt-3 pl-3">
                                        <DashboardConfirmBtn onClick={() => viewExpert(request.serviceId)}>View Expert</DashboardConfirmBtn>
                                    </div>

                                </div>
                                <div className="text-sm p-3 py-4 md:py-6 border-t">
                                    {request.description}
                                </div>
                                <div className="text-sm grid md:grid-cols-2 lg:grid-cols-3 gap-y-6 gap-x-4 justify-between p-3 py-4 md:py-6 border-t">
                                    <div className="inline-flex items-center gap-1 md:gap-1.5"><GrMoney />Budget: <span className="text-versich-dark-blue font-medium">{
                                        request.budget.uid === "other" ? request.otherBudget : request.budget.title
                                    }</span></div>
                                    <div className="inline-flex items-center gap-1 md:gap-1.5"><GiMoneyStack />Currency: <span className="text-versich-dark-blue font-medium">{request.currency.title}</span></div>
                                    {request.duration &&
                                        <div className="inline-flex items-center gap-1 md:gap-1.5"><MdCalendarMonth />Project Duration: <span className="text-versich-dark-blue font-medium">{
                                            request.duration.uid === "other" ? request.otherDuration : request.duration.title
                                        }</span></div>
                                    }
                                </div>
                                {(request.requirements || request.projectType || request.appPlatform
                                    || request.needs || request.serviceNeed || request.businessType || request.businessSize
                                    || request.industry || request.monetizationStrategy || request.decision)
                                    &&
                                    <div className="text-sm grid lg:grid-cols-2 gap-y-6 gap-x-4 justify-between p-3 py-4 md:py-6 border-t">
                                        {request.requirements &&
                                            <div className="inline-flex items-center gap-1 md:gap-1.5">Requirements: <span className="text-versich-dark-blue font-medium">{request.requirements.title}</span></div>
                                        }
                                        {request.projectType &&
                                            <div className="inline-flex items-center gap-1 md:gap-1.5">Project Type: <span className="text-versich-dark-blue font-medium">{
                                                request.projectType.uid === "other" ? request.otherProjectType : request.projectType.title
                                            }</span></div>
                                        }
                                        {request.appPlatform &&
                                            <div className="inline-flex items-center gap-1 md:gap-1.5">App Platform: <span className="text-versich-dark-blue font-medium">{
                                                request.appPlatform.uid === "other" ? request.otherAppPlatform : request.appPlatform.title
                                            }</span></div>
                                        }
                                        {request.softwareType &&
                                            <div className="inline-flex items-center gap-1 md:gap-1.5">Software Type: <span className="text-versich-dark-blue font-medium">{
                                                request.softwareType.uid === "other" ? request.otherSoftwareType : request.softwareType.title
                                            }</span></div>
                                        }
                                        {request.softwareNature &&
                                            <div className="inline-flex items-center gap-1 md:gap-1.5">Software Nature: <span className="text-versich-dark-blue font-medium">{
                                                request.softwareNature.uid === "other" ? request.otherSoftwareNature : request.softwareNature.title
                                            }</span></div>
                                        }
                                        {request.os &&
                                            <div className="inline-flex items-center gap-1 md:gap-1.5">Operating System: <span className="text-versich-dark-blue font-medium">{
                                                request.os.uid === "other" ? request.otherOs : request.os.title
                                            }</span></div>
                                        }
                                        {request.needs &&
                                            <div className="inline-flex items-center gap-1 md:gap-1.5">Needs: <span className="text-versich-dark-blue font-medium">{
                                                request.needs.uid === "other" ? request.otherNeed : request.needs.title
                                            }</span></div>
                                        }
                                        {request.serviceNeed &&
                                            <div className="inline-flex items-center gap-1 md:gap-1.5">Service Need: <span className="text-versich-dark-blue font-medium">{
                                                request.serviceNeed.uid === "other" ? request.otherServiceNeed : request.serviceNeed.title
                                            }</span></div>
                                        }
                                        {request.businessType &&
                                            <div className="inline-flex items-center gap-1 md:gap-1.5">Business Type: <span className="text-versich-dark-blue font-medium">{
                                                request.businessType.uid === "other" ? request.otherBusiness : request.businessType.title
                                            }</span></div>
                                        }
                                        {request.businessSize &&
                                            <div className="inline-flex items-center gap-1 md:gap-1.5">Business Size: <span className="text-versich-dark-blue font-medium">{request.businessSize.title} employees</span></div>
                                        }
                                        {request.industry &&
                                            <div className="inline-flex items-center gap-1 md:gap-1.5">Industry: <span className="text-versich-dark-blue font-medium">{
                                                request.industry.uid === "other" ? request.otherIndustry : request.industry.title
                                            }</span></div>
                                        }
                                        {request.monetizationStrategy &&
                                            <div className="inline-flex items-center gap-1 md:gap-1.5">Monetization Strategy: <span className="text-versich-dark-blue font-medium">{
                                                request.monetizationStrategy.uid === "other" ? request.otherMonetizationStrategy : request.monetizationStrategy.title
                                            }</span></div>
                                        }
                                        {request.serviceEngagement &&
                                            <div className="inline-flex items-center gap-1 md:gap-1.5">Service Engagement: <span className="text-versich-dark-blue font-medium">{
                                                request.serviceEngagement.uid === "other" ? request.otherServiceEngagement : request.serviceEngagement.title
                                            }</span></div>
                                        }
                                        {request.decision &&
                                            <div className="inline-flex items-center gap-1 md:gap-1.5">Hiring Decision: <span className="text-versich-dark-blue font-medium">{
                                                request.decision.uid === "other" ? request.otherDecision : request.decision.title
                                            }</span></div>
                                        }
                                        {request.service &&
                                            <div className="inline-flex items-center gap-1 md:gap-1.5">Custom Service: <span className="text-versich-dark-blue font-medium">{request.service}</span></div>
                                        }
                                        {request.expert &&
                                            <div className="inline-flex items-center gap-1 md:gap-1.5">Expert: <span className="text-versich-dark-blue font-medium">{request.expert.title}</span></div>
                                        }
                                    </div>
                                }
                                {/* Expert Services */}
                                {request.services &&
                                    <div className="text-sm p-3 py-4 md:py-6 border-t">
                                        {request.services &&
                                            <div className="space-y-4">
                                                <div className="text-versich-dark-blue font-semibold text-lg">Expert Services: </div>
                                                <div className="flex flex-wrap flex-col smallest:flex-row gap-2">
                                                    {request.services.map(service => (
                                                        <div className="bg-anti-flash-white text-versich-darktext-color text-xs md:text-sm py-1 px-2.5 border border-versich-border/40 rounded-2xl" key={service.uid}>{service.title}</div>
                                                    ))}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                                {/* Tools */}
                                {request.tools &&
                                    <div className="text-sm p-3 py-4 md:py-6 border-t">
                                        {request.tools &&
                                            <div className="space-y-4">
                                                <div className="text-versich-dark-blue font-semibold text-lg">Prefered Tools: </div>
                                                <div className="flex flex-wrap flex-col smallest:flex-row gap-2">
                                                    {request.tools.map(tool => (
                                                        <div className="bg-anti-flash-white text-versich-darktext-color text-xs md:text-sm py-1 px-2.5 border border-versich-border/40 rounded-2xl" key={tool.uid}>{tool.title}</div>
                                                    ))}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        : <div className="w-full h-80 flex justify-center items-center">
                            <BeatLoader loading={true} color="#1D88ED" size={10} />
                        </div>
                    }
                </div>
            </div>
    )
}
export default RequestDetails