import { useForm, FormProvider } from "react-hook-form"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { IoIosAlert } from "react-icons/io"

import Meta from "../../../../components/Meta"
import FormLoader from "../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import RadioSelectionField from "../../../../components/Inputs/RadioSelectionField"
import LocationSelectionField from "../../../../components/Inputs/LocationSelectionField"
import toastify from "../../../../utils/toastify"
import { dashboardPath, regionCoverage, regionCoverageOptions, TOASTTYPE_ERROR } from "../../../../assets/constants"
import { freelancerLeadsLocationCreateAsync } from "../../../../slices/freelancer/leadsLocation"

const LeadsForm = () => {
    const methods = useForm()
    const [csrfToken, setCsrfToken] = useState("")
    const [isWorldwide, setIsWorldwide] = useState(true)
    const [selectedCountry, setSelectedCountry] = useState("")
    const [selectedState, setSelectedState] = useState("")
    const [regionError, setRegionError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    const handleRadioChange = (value) => {
        setIsWorldwide(value === regionCoverage.worldwide.value)
    }
    const handleLocationChange = (country, state) => {
        setSelectedCountry(country)
        setSelectedState(state)
    }

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const isValid = await methods.trigger()
            if (!isValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            let formData
            if (isWorldwide) {
                formData = {
                    token: csrfToken,
                    coverage: regionCoverage.worldwide.value,
                }
            }
            else {
                if (!selectedCountry || selectedState === "") {
                    setRegionError(true)
                    return
                }
                formData = {
                    token: csrfToken,
                    coverage: regionCoverage.custom.value,
                    countryiso3: selectedCountry,
                    statecode: selectedState,
                }
            }
            setLoading(true)
            await dispatch(freelancerLeadsLocationCreateAsync(formData))
                .unwrap()
                .then(() => methods.reset())
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => {
                    setLoading(false)
                    setRegionError(false)
                })
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <FormProvider {...methods}>
            <Meta title="Setup - Leads Location" description="Complete leads registration" />
            <div className="py-10 md:py-14 px-3 mb-12 overflow-hidden bg-versich-primary-bg flex items center justify-center">
                <form className="w-full bg-white shadow-form py-5 md:py-10 px-5 md:px-10 max-w-[580px] rounded-md space-y-6" onSubmit={handleSubmit}>
                    <div className="space-y-5">
                        <h2 className="text-start text-xl text-versich-dark-blue font-semibold">Where would you like to see leads from?</h2>
                        <p className="text-start">Tell us the area you cover so we can show you leads for your location</p>
                    </div>
                    <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                    <RadioSelectionField options={regionCoverageOptions} onChange={handleRadioChange} />
                    <LocationSelectionField notWorldwide={!isWorldwide} onLocationChange={handleLocationChange} regionError={regionError} />
                    {errorMsg && <div className="text-sm text-red-500 text-left !mt-10">{errorMsg}</div>}
                    <div className="flex gap-x-3 items-start text-start">
                        <IoIosAlert className="text-gray-500 text-2xl" />
                        <p>You can change your location at any time</p>
                    </div>
                    <div className="w-full flex justify-between">
                        <button className="bg-slate-100 hover:bg-slate-200 text-gray-900 border border-versich-border/20 rounded-md py-2 lg:py-2.5 px-5 lg:px-7" type="button" onClick={() => navigate(dashboardPath)}>Back</button>
                        <button className="bg-versich-blue hover:bg-versich-blue-hover text-white rounded-md py-2 lg:py-2.5 px-5 lg:px-7" type="submit">Next</button>
                    </div>
                </form>
            </div>
            {loading && <FormLoader />}
        </FormProvider>
    )
}
export default LeadsForm
