import { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import CSRFTokenField from "../../../components/Inputs/CSRFTokenField"
import ConfirmButton from "../../../components/Buttons/ConfirmButton"
import { validateForm } from "../../../utils/form"
import { searchServiceAsync } from "../../../slices/search"
import { newRequestPath, servicesPath } from "../../../assets/constants"
import SearchSuggestionsInput from "./SearchSuggestionsInput"
import ServicesList from "../../../assets/ServicesList"

const ServicesNames = ServicesList.map(service => service.name)

const SearchBar = () => {
    const [csrfToken, setCsrfToken] = useState("")
    const [query, setQuery] = useState("")
    const [suggestions, setSuggestions] = useState([])

    const dispatch = useDispatch()
    const handleChange = (e) => {
        e.preventDefault()
        let query = e.target.value
        setQuery(query)
        if (!query) {
            return setSuggestions([])
        }
        dispatch(searchServiceAsync({ query, token: csrfToken }))
            .unwrap()
            .then((data) => setSuggestions(data))
            .catch(() => setSuggestions([]))
    }

    const navigate = useNavigate()
    const handleSubmit = (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        if (validateForm(e.target, submitBtn, "Enter the keywords / service you're looking!")) {
            let serviceIndex = ServicesNames.indexOf(query)
            if (serviceIndex >= 0) {
                navigate(`${newRequestPath + ServicesList[serviceIndex].uid}/`)
            }
            else if (suggestions.length === 1) {
                navigate(`${newRequestPath + suggestions[0].uid}/`)
            }
            else navigate(servicesPath)
        }
    }
    return (
        <form className="flex gap-x-4 mt-6 sm:w-[85%]" onSubmit={handleSubmit}>
            <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
            <SearchSuggestionsInput onChange={handleChange} value={query} setValue={setQuery} suggestions={suggestions} />
            <ConfirmButton className="bg-versich-blue hover:bg-opacity-80 h-full p-2 px-3 lg:px-5 rounded-lg cursor-pointer" type="submit">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                </svg>
            </ConfirmButton>
        </form>
    )
}
export default SearchBar
