import { configureStore } from "@reduxjs/toolkit"
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync"

import authReducer from "./reducers/auth"
import authVerifyReducer from "./reducers/authverify"
import profileCompletionReducer from "./slices/profileCompletion"

const store = configureStore({
    reducer: {
        auth: authReducer,
        authVerify: authVerifyReducer,
        profileCompletion: profileCompletionReducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat(createStateSyncMiddleware({}))
    },
    devTools: process.env.NODE_ENV !== "production",
})
initMessageListener(store)
export default store
