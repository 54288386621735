import { useSelector, useDispatch } from "react-redux"
import { useForm, FormProvider } from "react-hook-form"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import Meta from "../../../../components/Meta"
import FormLoader from "../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import InputText from "../../../../components/Inputs/InputTextField"
import TextAreaField from "../../../../components/Inputs/TextAreaField"
import PhoneNumberField from "../../../../components/Inputs/PhoneNumberField"
import ChoiceButton from "../../../../components/Buttons/ChoiceButton"
import DropdownField from "../../../../components/Inputs/DropdownField"
import toastify from "../../../../utils/toastify"
import { companySizes, dashboardPath, TOASTTYPE_ERROR } from "../../../../assets/constants"
import { freelancerCreateAboutAsync } from "../../../../slices/freelancer/about"

const ProfileForm = () => {
    const { user } = useSelector((state) => state.auth)
    const methods = useForm()

    const [csrfToken, setCsrfToken] = useState("")
    const [phoneCode, setPhoneCode] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [phoneNumberValid, setPhoneNumberValid] = useState(true)
    const [hasWebsite, setHasWebsite] = useState(false)
    const [companySize, setCompanySize] = useState("")
    const [hasSalesTeam, setHasSalesTeam] = useState(false)
    const [hasSocialMedia, setHasSocialMedia] = useState(true)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Validate Phone number
    const validateNumber = (phoneNumber) => {
        if (!phoneNumber.trim()) {
            return false
        }
        const phoneNumberPattern = /^\+?\d{1,}$/
        return phoneNumberPattern.test(phoneNumber)
    }
    const handlePhoneNumberChange = (phone, country) => {
        setPhoneCode(country.dialCode)
        setPhoneNumber(phone.replace(country.dialCode, ""))
        setPhoneNumberValid(validateNumber(phone))
    }

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const onSubmit = async (data) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid || !phoneNumberValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            const formData = {
                token: csrfToken,
                companyName: data.companyName,
                address: data.address,
                phonecode: `+${phoneCode}`,
                phonenumber: phoneNumber,
                ...(hasWebsite && {
                    website: hasWebsite ? data.website : null,
                }),
                companysize: companySize,
                salesteam: hasSalesTeam,
                socialmedia: hasSocialMedia
            }
            setLoading(true)
            await dispatch(freelancerCreateAboutAsync(formData))
                .unwrap()
                .then(() => methods.reset())
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <FormProvider {...methods}>
            <Meta title="Setup - Profile" description="Complete profile registration" />
            <div className="py-10 md:py-14 px-3 mb-12 overflow-hidden bg-versich-primary-bg flex items center justify-center">
                <div className="w-full bg-white shadow-form py-5 md:py-10 px-5 md:px-10 max-w-[580px] rounded-md space-y-6">
                    <div className="space-y-5">
                        <h2 className="text-start text-xl text-versich-dark-blue font-semibold">Some details about you</h2>
                        <p className="text-start">You’re just a few steps away from viewing our <span className="text-versich-light-blue">{user.primaryService?.title}</span> leads</p>
                    </div>
                    <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-5">
                        <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                        <InputText name="name" inputType="text" label="Your Name" rules={{ required: "Field is required" }} defaultValue={user ? `${user.first_name} ${user.last_name}` : ""} />
                        <InputText name="companyName" inputType="text" label="Company Name" rules={{ required: "Field is required" }} />
                        <TextAreaField name="address" label="Address" rules={{ required: "Field is required" }} />
                        <PhoneNumberField name="phonenumber" onChange={handlePhoneNumberChange} rules={{ required: "This field is required" }} />
                        <div className="text-start flex flex-col">
                            <p className="mb-2 text-versich-label text-sm">Does your company have a website?</p>
                            <div className="flex gap-4">
                                <ChoiceButton selected={hasWebsite} onClick={() => setHasWebsite(prev => !prev)}>Yes</ChoiceButton>
                                <ChoiceButton selected={!hasWebsite} onClick={() => setHasWebsite(prev => !prev)}>No</ChoiceButton>
                            </div>
                        </div>
                        {hasWebsite &&
                            <InputText name="website" inputType="url" placeholder="Website address" rules={{ required: "Field is required" }} />
                        }
                        <DropdownField
                            name="companySize"
                            label="Company size, employees"
                            options={companySizes}
                            value={companySize}
                            placeholder="Select number of Employee"
                            rules={{ required: "Field is required" }}
                            onChange={(e) => setCompanySize(e.target.value)}
                        />
                        <div className="text-start flex flex-col">
                            <p className="mb-2 text-versich-label text-sm">Does your company have a sales team?</p>
                            <div className="flex gap-4">
                                <ChoiceButton selected={hasSalesTeam} onClick={() => setHasSalesTeam(prev => !prev)}>Yes</ChoiceButton>
                                <ChoiceButton selected={!hasSalesTeam} onClick={() => setHasSalesTeam(prev => !prev)}>No</ChoiceButton>
                            </div>
                        </div>
                        <div className="text-start flex flex-col ">
                            <p className="mb-2 text-versich-label text-sm">Does your company use social media?</p>
                            <div className="flex gap-4">
                                <ChoiceButton selected={hasSocialMedia} onClick={() => setHasSocialMedia(prev => !prev)}>Yes</ChoiceButton>
                                <ChoiceButton selected={!hasSocialMedia} onClick={() => setHasSocialMedia(prev => !prev)}>No</ChoiceButton>
                            </div>
                        </div>
                        {errorMsg && <div className="text-sm text-red-500 text-left !mt-10">{errorMsg}</div>}
                        <div className="flex justify-between pt-6">
                            <button className="bg-slate-100 hover:bg-slate-200 text-gray-900 border border-versich-border/20 rounded-md py-2 lg:py-2.5 px-5 lg:px-7" type="button" onClick={() => navigate(dashboardPath)}>Back</button>
                            <button className="bg-versich-blue hover:bg-versich-blue-hover text-white rounded-md py-2 lg:py-2.5 px-5 lg:px-7" type="submit">Next</button>
                        </div>
                    </form>
                </div>
            </div>
            {loading && <FormLoader />}
        </FormProvider>
    )
}
export default ProfileForm
