import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../../api"
import apiErrorParser from "../../../utils/apiErrorParser"

export const allSoftwareTypeAsync = createAsyncThunk("service/SD/allSoftwareTypeAsync", async (params) => {
    try {
        const { data } = await api.softwareTypesSD(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const allSoftwareNatureAsync = createAsyncThunk("service/SD/allSoftwareNatureAsync", async (params) => {
    try {
        const { data } = await api.softwareNaturesSD(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const allOperatingSystemAsync = createAsyncThunk("service/SD/allOperatingSystemAsync", async (params) => {
    try {
        const { data } = await api.operatingSystemsSD(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const allIndustryTypeAsync = createAsyncThunk("service/SD/allIndustryTypeAsync", async (params) => {
    try {
        const { data } = await api.industryTypesSD(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const allServiceEngagementAsync = createAsyncThunk("service/SD/allServiceEngagementAsync", async (params) => {
    try {
        const { data } = await api.serviceEngagementsSD(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const createRequestAsync = createAsyncThunk("account/SD/createRequest",
    async ({
        softwareType, otherSoftwareType, softwareNature, otherSoftwareNature, operatingSystem, otherOperatingSystem,
        industryType, otherIndustryType, serviceEngagement, otherServiceEngagement, currency, budget, otherBudget,
        hiringDecision, otherHiringDecision, clientEmail, clientName, description, token
    }) => {
        try {
            const { data } = await api.createRequestSD({
                softwareTypeId: softwareType,
                otherSoftwareType,
                softwareNatureId: softwareNature,
                otherSoftwareNature,
                osId: operatingSystem,
                otherOS: otherOperatingSystem,
                industryTypeId: industryType,
                otherIndustryType,
                currencyId: currency,
                estimatedBudgetId: budget,
                otherEstimatedBudget: otherBudget,
                serviceEngagementId: serviceEngagement,
                otherServiceEngagement,
                hiringDecisionId: hiringDecision,
                otherHiringDecision,
                clientEmail,
                clientName,
                description,
                csrfmiddlewaretoken: token
            })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })

export const getRequestAsync = createAsyncThunk("account/SD/getRequest", async (refId) => {
    try {
        const { data } = await api.getRequestSD(refId)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})