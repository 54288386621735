import { useFormContext } from "react-hook-form"
import { useEffect } from "react"
import { IoIosArrowDown } from "react-icons/io"

const DropdownField = ({ label, options, value, placeholder, name, rules, onChange }) => {
    const { register, formState, setValue } = useFormContext()
    const { errors } = formState

    useEffect(() => {
        setValue(name, value)
    }, [value, name])
    return (
        <div className="space-y-2 text-start">
            {label && <label htmlFor={name} className="text-versich-label text-sm">{label}</label>}
            <div className="relative">
                <select
                    id={name}
                    className="w-full h-10 rounded-md border-[1px] border-versich-border px-3 pr-8 appearance-none focus:outline-none cursor-pointer hover:border-versich-blue"
                    name={name}
                    {...register(name, rules)}
                    value={value}
                    onChange={onChange}
                >
                    <option value="" disabled>{placeholder}</option>
                    {options.map((option) => (
                        <option className="w-full h-10 rounded-md border-[1px] border-versich-border px-3 pr-8 appearance-none focus:outline-none" value={option.value} key={option.value}>{option.label}</option>
                    ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                    <IoIosArrowDown className="w-5 h-5 text-gray-400" />
                </div>
            </div>
            {errors[name] &&
                <span className="text-sm text-red-500">{errors[name].message}</span>
            }
        </div>
    )
}
export default DropdownField
