import { useState, useEffect } from "react"
import { MdRadioButtonChecked } from "react-icons/md"
import { MdOutlineRadioButtonUnchecked } from "react-icons/md"

const RadioSelectionField = ({ label, selected, options, onChange }) => {
    const [selectedOption, setSelectedOption] = useState(options[0].value)

    useEffect(() => {
        selected && setSelectedOption(selected)
    }, [selected])

    const handleOptionChange = (e) => {
        const value = e.target.value
        setSelectedOption(value)
        onChange(value)
    }
    return (
        <div className="space-y-2 text-start">
            {label && <div>{label}</div>}
            <div className="flex flex-col gap-y-5">
                {options.map((option) => (
                    <label className="flex items-center gap-x-3 cursor-pointer hover:text-versich-blue-hover" key={option.value}>
                        <input name={label} className="appearance-none" type="radio" value={option.value} checked={selectedOption === option.value} onChange={handleOptionChange} />
                        {selectedOption === option.value
                            ? <MdRadioButtonChecked className="text-versich-blue text-xl" />
                            : <MdOutlineRadioButtonUnchecked className="text-versich-blue text-xl" />
                        }{option.label}
                    </label>
                ))}
            </div>
        </div>
    )
}
export default RadioSelectionField
