import hero from "../../../assets/images/hero.jpg"
import SearchBar from "./SearchBar"

const Hero = () => {
    return (
        <div style={{ backgroundImage: `url(${hero})` }} className="background-image bg-cover bg-center bg-no-repeat mt-[-100px]">
            <div className="bg-black bg-opacity-80 text-white text-start w-full h-full px-8 md:px-20 lg:px-48 pt-[200px] pb-[124px]">
                <div className="max_screen_w">
                    <div className="text-4xl lg:text-5xl font-[700]">
                        <h1 className="leading-tight">
                            <div>Find The </div>
                            <div>Perfect Professional </div>
                            <div>For You </div>
                        </h1>
                        <p className="text-xl tracking-wide py-6 font-[400]">Get free quotes within minutes</p>
                    </div>
                    <SearchBar />
                </div>
            </div>
        </div>
    )
}
export default Hero
