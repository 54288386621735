import { useSelector, useDispatch } from "react-redux"
import { useForm, FormProvider } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"

import Meta from "../../../../components/Meta"
import FormLoader from "../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import InputText from "../../../../components/Inputs/InputTextField"
import PhoneNumberField from "../../../../components/Inputs/PhoneNumberField"
import CountrySelectionField from "../../../../components/Inputs/CountrySelectionField"
import ChoiceButton from "../../../../components/Buttons/ChoiceButton"
import toastify from "../../../../utils/toastify"
import { dashboardPath, TOASTTYPE_ERROR } from "../../../../assets/constants"
import { clientUpdateAboutAsync } from "../../../../slices/client/about"

const EditClientProfile = () => {
    const { user } = useSelector((state) => state.auth)
    const methods = useForm()
    const navigate = useNavigate()

    const [csrfToken, setCsrfToken] = useState("")
    const [phoneCode, setPhoneCode] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [phoneNumberValid, setPhoneNumberValid] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState("")
    const [regionError, setRegionError] = useState(false)
    const [hasLinkedinProfile, setHasLinkedinProfile] = useState(false)
    const [linkedinUrl, setLinkedinUrl] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!user || !user.about) {
            return
        }
        const code = user.about.phone.code.replace("+", "")
        const fullPhoneNumber = code + user.about.phone.number
        setPhoneCode(code)
        setPhoneNumber(user.about.phone.number)
        setPhoneNumberValid(validateNumber(fullPhoneNumber))
        setSelectedCountry(user.about.country.iso3)
        if (user.about.linkedinUrl) {
            setHasLinkedinProfile(true)
            setLinkedinUrl(user.about.linkedinUrl)
        }
    }, [user])

    // Validate Phone number
    const validateNumber = (phoneNumber) => {
        if (!phoneNumber.trim()) {
            return false
        }
        const phoneNumberPattern = /^\+?\d{1,}$/
        return phoneNumberPattern.test(phoneNumber)
    }
    const handlePhoneNumberChange = (phone, country) => {
        setPhoneCode(country.dialCode)
        setPhoneNumber(phone.replace(country.dialCode, ""))
        setPhoneNumberValid(validateNumber(phone))
    }

    const dispatch = useDispatch()
    const onSubmit = async (data) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid || !phoneNumberValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }

            if (!selectedCountry) {
                setRegionError(true)
                return
            }
            const formData = {
                token: csrfToken,
                phonecode: `+${phoneCode}`,
                phonenumber: phoneNumber,
                countryiso3: selectedCountry,
                ...(hasLinkedinProfile && {
                    linkedin_url: hasLinkedinProfile ? data.linkedin : null,
                }),
            }
            setLoading(true)
            await dispatch(clientUpdateAboutAsync(formData))
                .unwrap()
                .then(() => {
                    navigate(dashboardPath)
                    methods.reset()
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => {
                    setRegionError(false)
                    setLoading(false)
                })
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <FormProvider {...methods}>
            <Meta title="Profile" description="Complete profile registration" />
            <div className="py-10 md:py-14 px-3 mb-12 overflow-hidden bg-versich-primary-bg flex items center justify-center">
                <div className="w-full bg-white shadow-form py-5 md:py-10 px-5 md:px-10 max-w-[580px] rounded-md space-y-6">
                    <div className="space-y-5">
                        <h2 className="text-start text-xl text-versich-dark-blue font-semibold">Edit your profile details</h2>
                    </div>
                    <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-5">
                        <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                        <PhoneNumberField name="phonenumber" onChange={handlePhoneNumberChange} rules={{ required: "This field is required" }} value={phoneCode + phoneNumber} />
                        <CountrySelectionField name="country" label="Country" selectedCountry={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)} regionError={regionError} />
                        <div className="text-start flex flex-col">
                            <p className="mb-2 text-versich-label text-sm">Do you have a linkedin profile?</p>
                            <div className="flex gap-4">
                                <ChoiceButton selected={hasLinkedinProfile} onClick={() => setHasLinkedinProfile(prev => !prev)}>Yes</ChoiceButton>
                                <ChoiceButton selected={!hasLinkedinProfile} onClick={() => setHasLinkedinProfile(prev => !prev)}>No</ChoiceButton>
                            </div>
                        </div>
                        {hasLinkedinProfile &&
                            <InputText name="linkedin" inputType="url" placeholder="Linkedin url" onChange={(e) => setLinkedinUrl(e.target.value)} defaultValue={linkedinUrl} rules={{ required: "Field is required" }} />
                        }
                        {errorMsg && <div className="text-sm text-red-500 text-left !mt-10">{errorMsg}</div>}
                        <div className="flex justify-between pt-6">
                            <button className="bg-slate-100 hover:bg-slate-200 text-gray-900 border border-versich-border/20 rounded-md py-2 lg:py-2.5 px-5 lg:px-7" type="button" onClick={() => navigate(dashboardPath)}>Cancel</button>
                            <button className="bg-versich-blue hover:bg-versich-blue-hover text-white rounded-md py-2 lg:py-2.5 px-5 lg:px-7" type="submit">Update</button>
                        </div>
                    </form>
                </div>
            </div>
            {loading && <FormLoader />}
        </FormProvider>
    )
}
export default EditClientProfile
