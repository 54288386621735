import { useLocation } from "react-router-dom"
import { useEffect } from "react"

const ScrollToTop = (props) => {
    const location = useLocation()
    useEffect(() => {
        const hashChanged = location.hash !== "" && location.hash !== previousLocation.hash
        if (!hashChanged && location.pathname !== previousLocation.pathname) {
            document.documentElement.scrollTo(0, 0)
        }
        else if (hashChanged) {
            let target = document.querySelector(location.hash)
            if (target) {
                let clearance = 0
                if (target.dataset.clearance) {
                    clearance = target.dataset.clearance
                }
                document.documentElement.scrollTo(target.offsetLeft, target.offsetTop - clearance)
            }
        }
        previousLocation = location
    }, [location])
    return <>{props.children}</>
}
var previousLocation = {
    pathname: "",
    search: "",
    hash: "",
    state: null,
    key: ""
}
export default ScrollToTop