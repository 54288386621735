import { useSelector, useDispatch } from "react-redux"
import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"

import { profileCompletionScoreAsync } from "../../../slices/profileCompletion"
import { CLIENT, FREELANCER, emailVerificationPath, setupPath, editProfilePath } from "../../../assets/constants"
import CircularProgressBar from "../../../components/CircularProgressBar"
import ConfirmButton from "../../../components/Buttons/ConfirmButton"

const ProfileCard = ({ className }) => {
    const { user } = useSelector((state) => state.auth)
    const profileCompletionScore = useSelector((state) => state.profileCompletion.score)
    const initials = `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`

    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(profileCompletionScoreAsync(user.account_type))
    }, [dispatch])
    return (
        <div className={className}>
            <div className="bg-white shadow-card px-4 py-4 sm:px-6 sm:py-6 rounded-xl">
                <div className="flex gap-x-3 items-center ">
                    {/* Avatar */}
                    <div className="rounded-full text-white text-lg lg:text-xl font-semibold bg-versich-light-blue w-[50px] h-[50px] lg:h-16 lg:w-16 flex justify-center items-center">
                        {initials}
                    </div>
                    <div className="flex-1 text-start text-versich-dark-blue font-semibold text-lg lg:text-xl">{user.first_name + " " + user.last_name}</div>
                </div>
                <div className="flex flex-col items-center gap-y-3 py-4 px-2.5 bg-[#D9EBFC] my-4 rounded-lg">
                    <CircularProgressBar progress={profileCompletionScore} />
                    {profileCompletionScore === 100
                        ? <p>Your profile is completed</p>
                        : user.account_type === CLIENT
                            ? <p>Complete your profile <Link to={!user.about ? setupPath : emailVerificationPath} className="text-versich-blue underline">here</Link></p>
                            : <p>Complete your profile <Link to={!user.primaryService || !user.leadsLocation || !user.about ? setupPath : emailVerificationPath} className="text-versich-blue underline">here</Link></p>
                    }
                </div>
                {(profileCompletionScore === 100
                    || (user.account_type === CLIENT && user.about)
                    || (user.account_type === FREELANCER && user.primaryService && user.leadsLocation && user.about)
                ) &&
                    <div className="max-w-32 lg:max-w-40">
                        <ConfirmButton type="button" handleClick={() => navigate(editProfilePath)}>Edit profile</ConfirmButton>
                    </div>
                }
            </div>
        </div>
    )
}
export default ProfileCard