import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useLocation, Link } from "react-router-dom"
import { useFormContext } from "react-hook-form"
import { IoIosArrowBack } from "react-icons/io"

import Meta from "../../../components/Meta"
import CSRFTokenField from "../../../components/Inputs/CSRFTokenField"
import InputTextField from "../../../components/Inputs/InputTextField"
import ConfirmButton from "../../../components/Buttons/ConfirmButton"
import SocialAccounts from "../socialAccounts/SocialAccounts"
import { SS_ACCOUNT_TYPE, loginPath } from "../../../assets/constants"
import { signupUser } from "../../../actions/auth"

const SignupForm = ({ setShowSignupForm, accountType }) => {
    const methods = useFormContext()

    const [csrfToken, setCsrfToken] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [notEqual, setNotEqual] = useState(null)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Pre-populate user data from request forms
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        console.log(searchParams)
        const qEmail = searchParams.get("email")
        if (qEmail) {
            setEmail(qEmail)
        }
        // Fill first & last name based on user entered name
        const qName = searchParams.get("name")
        if (qName) {
            const names = String(qName).split(" ")
            if (names.length > 2) {
                setFirstName(names.slice(0, names.length - 1).join(" "))
                setLastName(names[names.length - 1])
            }
            else if (names.length === 2) {
                setFirstName(names[0])
                setLastName(names[1])
            }
            else {
                setFirstName(qName)
                setLastName("")
            }
        }
    }, [])

    const dispatch = useDispatch()
    const onSubmit = async (data) => {
        if (data.password !== data.confirmPassword) {
            return setNotEqual(true)
        }
        setLoading(true)
        dispatch(signupUser(accountType, firstName, lastName, email, password, csrfToken))
            .then((data) => {
                if (data.status !== "success") {
                    setErrorMsg(data.message)
                }
                sessionStorage.removeItem(SS_ACCOUNT_TYPE.key)
                methods.reset()
            })
            .catch((error) => {
                if (error.response && error.response.status) {
                    setErrorMsg(error.message)
                    return error
                }
                setErrorMsg(error.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const location = useLocation()
    return (
        <div className="py-10 md:py-14 px-3 overflow-hidden flex justify-center bg-versich-primary-bg items-center">
            <Meta title="Signup" description="Sign up for a new account." />
            <div className="w-full bg-white shadow-form my-6 py-5 md:py-10 px-5 md:px-10 max-w-[580px] rounded-lg">
                <div className="text-start">
                    <button className="bg-versich-border/20 inline-flex p-2 rounded-full cursor-pointer group" onClick={() => setShowSignupForm(false)} title="Back">
                        <IoIosArrowBack className="text-xl group-hover:fill-versich-blue-hover group-active:fill-versich-blue-hover" />
                    </button>
                </div>
                <h2 className="text-2xl lg:text-3xl leading-normal text-left my-5 text-versich-darktext-color font-medium ">Create a {accountType} account</h2>
                <div className="w-full">
                    <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                        <div className="space-y-6 md:space-y-7 text-start ">
                            <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                            <div className="grid grid-cols-1 gap-x-5 gap-6 md:grid-cols-2">
                                <InputTextField name="firstname" inputType="text" label="First Name" rules={{ required: "First name is required" }} onChange={(e) => setFirstName(e.target.value)} defaultValue={firstName} />
                                <InputTextField name="lastname" inputType="text" label="Last Name" rules={{ required: "Last name is required" }} onChange={(e) => setLastName(e.target.value)} defaultValue={lastName} />
                            </div>
                            <InputTextField
                                name="email"
                                inputType="email"
                                label="Email Address"
                                rules={{
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                        message: "Email is invalid",
                                    },
                                }}
                                onChange={(e) => setEmail(e.target.value)}
                                defaultValue={email}
                            />
                            <InputTextField
                                name="password"
                                inputType="password"
                                label="Password"
                                placeholder="*************"
                                rules={{
                                    required: "Password is required",
                                    minLength: {
                                        value: 8,
                                        message: "Password must be at least 8 characters",
                                    },
                                    // pattern: {
                                    //     value: /^(?=.*[!@#$%^&*()_+]).+$/, // ^(?=.*[!@#$%^&*()_+])[!@#$%^&*()_+]+$
                                    //     message: "Atleast one special character required",
                                    // },
                                }}
                            />
                            <InputTextField
                                name="confirmPassword"
                                inputType="password"
                                label="Confirm Password"
                                placeholder="*************"
                                rules={{ required: "Password is required" }}
                                onChange={(e) => {
                                    setNotEqual(false);
                                    setPassword(e.target.value)
                                }}
                            />
                            {notEqual && <span className="text-sm text-red-500">Passwords do not match</span>}
                            {errorMsg && <div className="text-sm text-red-500 text-left">{errorMsg}</div>}
                            <p className="text-sm text-left">
                                By continuing, you agree to the <a className="text-versich-blue underline hover:no-underline hover:text-versich-blue-hover" href="#terms-of-use">Terms of use</a> and <a className="text-versich-blue underline hover:no-underline hover:text-versich-blue-hover" href="#privacy-policy">Privacy Policy</a>.
                            </p>
                            <ConfirmButton type="submit" loading={loading}>Create account</ConfirmButton>
                            <p className="text-sm ">
                                Already have an account?{" "}
                                <Link to={loginPath + location.search} className="text-versich-blue hover:text-versich-blue-hover hover:underline">Log in</Link>
                            </p>
                            <SocialAccounts google={{ url: process.env.REACT_APP_API_GOOGLE_OAUTH2_URL, text: "Continue with Google" }} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default SignupForm
