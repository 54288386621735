import ServicesList from "../../../../assets/ServicesList"

export const fetchServiceId = (refId) => {
    const words = refId.split("_")
    const serviceCode = words[0]
    switch (serviceCode) {
        case "WDAD":
            return ServicesList[0].uid
        case "MA":
            return ServicesList[1].uid
        case "DAA":
            return ServicesList[2].uid
        case "SD":
            return ServicesList[3].uid
        case "FT":
            return ServicesList[4].uid
        case "MLAI":
            return ServicesList[5].uid
        default:
            break
    }
    return null
}