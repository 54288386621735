
const ChoiceButton = ({ children, selected, onClick }) => {
    return (
        <div className="text-start">
            <button className={`px-4 lg:px-6 py-1.5 lg:py-2 ${selected ? "bg-versich-blue text-white" : "bg-gray-200 text-gray-800"} rounded-md`} type="button" onClick={onClick}>
                {children}
            </button>
        </div>
    )
}
export default ChoiceButton
