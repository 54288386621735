import { FormProvider, useForm } from "react-hook-form"
import { useEffect, useState } from "react"

import { CLIENT, FREELANCER, SS_ACCOUNT_TYPE } from "../../../assets/constants"
import AccountSelection from "./AccountSelectionForm"
import SignupForm from "./SignupForm"

const Signup = () => {
    const methods = useForm()
    const [showSignupForm, setShowSignupForm] = useState(false)

    // Fetch accounttype or redirect to account selection
    const accountType = window.sessionStorage.getItem(SS_ACCOUNT_TYPE.key)
    useEffect(() => {
        setShowSignupForm(
            [FREELANCER, CLIENT].includes(accountType)
        )
    }, [])
    return (
        <FormProvider {...methods}>
            {!showSignupForm
                ? <AccountSelection onSubmit={() => setShowSignupForm(true)} />
                : <SignupForm setShowSignupForm={setShowSignupForm} accountType={accountType} />
            }
        </FormProvider>
    )
}
export default Signup
