import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import Meta from "../../../components/Meta"
import { SS_VERIFICATION_STATUS, emailVerificationPath } from "../../../assets/constants"

const DashboardLayout = ({ children }) => {
    const { user } = useSelector((state) => state.auth)

    // Clean session cookie
    window.sessionStorage.removeItem(SS_VERIFICATION_STATUS.key)

    // Welcome greeting
    const currentHour = new Date().getHours()
    let greeting = ""
    if (currentHour >= 5 && currentHour < 12) {
        greeting = "Good Morning"
    } else if (currentHour >= 12 && currentHour < 18) {
        greeting = "Good Afternoon"
    } else {
        greeting = "Good Evening"
    }
    return (
        <div className="bg-versich-primary-bg px-6 md:px-16 lg:px-28 py-10">
            <Meta title="Dashboard" description="VersiMarket user dashboard" />
            <div className="max_screen_w space-y-10">
                <div className="bg-white shadow-card w-full px-4 py-4 sm:px-6 sm:py-6 font-semibold text-start text-versich-dark-blue rounded-xl flex justify-between flex-col sm:flex-row">
                    <p>{greeting}, {user.first_name}!</p>
                    {!user.verified &&
                        <Link to={emailVerificationPath} className="text-versich-blue underline hover:text-versich-blue-hover">Verify Email</Link>
                    }
                </div>
                {children}
            </div>
        </div>
    )
}
export default DashboardLayout