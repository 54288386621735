import { EMPTY, HIGHPARENT, TOASTTYPE_ERROR } from "../assets/constants"
import toastify from "./toastify"

export const validateForm = (form, sumtbtn, errorHint = "", toastContainerId = null) => {
    let hasEmptyField = false
    form.querySelectorAll(".required").forEach(field => {
        if (field.value === ""
            || (field.dataset?.notequals && field.value === field.dataset.notequals)) {
            highlightField(form, sumtbtn, field)
            hasEmptyField = true
        }
    })
    if (hasEmptyField) {
        errorHint === "" && (errorHint = "Fill the required fields, before submitting.")
        toastContainerId
            ? toastify(errorHint, TOASTTYPE_ERROR, sumtbtn, toastContainerId)
            : toastify(errorHint, TOASTTYPE_ERROR, sumtbtn)
    }
    return !hasEmptyField
}
const highlightField = (form, sumtbtn, field) => {
    field.classList.contains(HIGHPARENT) ? field.parentElement.classList.add(EMPTY) : field.classList.add(EMPTY)
    field.oninput = () => {
        field.classList.contains(HIGHPARENT) ? field.parentElement.classList.remove(EMPTY) : field.classList.remove(EMPTY)
        field.oninput = null
        const emptyFieldsCount = form.querySelectorAll(".required.empty").length
        if (emptyFieldsCount === 0) {
            sumtbtn.disabled = false
        }
    }
}