import { useSelector } from "react-redux"

import Meta from "../../../../components/Meta"
import { CLIENT, FREELANCER } from "../../../../assets/constants"
import EditClientProfile from "./ClientProfile"
import EditFreelancerProfile from "./FreelancerProfile"

const EditProfile = () => {
    const { user } = useSelector((state) => state.auth)
    return (<>
        <Meta title="Edit Profile" description="Edit user profile" />
        {user.account_type === CLIENT && <EditClientProfile />}
        {user.account_type === FREELANCER && <EditFreelancerProfile />}
    </>)
}
export default EditProfile
