import { useState } from "react"

import expertNeedIcon from "../../../../../../assets/icons/request/expert-need.png"
import NewRequestLayout from "../../NewRequestLayout"
import RadioOptions from "../../components/RadioOptions"
import { allMobileAppPlatformAsync } from "../../../../../../slices/client/service/mobileApp"

const AppPlatform = ({ totalForms, formPosition, updateFormPosition, defaultValue, setAppPlatform, setOtherAppPlatform, goBack }) => {
    const [value, setValue] = useState(defaultValue || "")
    const [otherValue, setOtherValue] = useState("")
    const [error, setError] = useState(false)
    const [otherInputError, setOtherInputError] = useState(false)

    const handleContinue = () => {
        if (value === "other") {
            if (otherValue) {
                setAppPlatform(value)
                setOtherAppPlatform(otherValue)
            }
            else {
                setOtherInputError(true)
                setTimeout(() => {
                    setOtherInputError(false)
                }, 2000)
            }
        }
        else if (value) {
            setAppPlatform(value)
        }
        else {
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 2000)
        }
    }
    return (
        <NewRequestLayout
            icon={expertNeedIcon}
            description="Select an option to let us know what platform you plan to run on."
            totalForms={totalForms} currentForm={formPosition}
            handleBackClick={goBack}
            handleContinueClick={handleContinue}
        >
            <div className="relative">
                <p className="text-versich-dark-blue text-lg font-semibold pb-3">Which platform(s) is this needed for?</p>
                <RadioOptions
                    actionAsync={allMobileAppPlatformAsync}
                    updateFormPosition={updateFormPosition}
                    value={value}
                    setValue={setValue}
                    error={error}
                    setOtherValue={setOtherValue}
                    otherInputError={otherInputError}
                />
            </div>
        </NewRequestLayout>
    )
}
export default AppPlatform
