
const apiErrorParser = (error) => {
    let _message = ""
    if (error?.name === "AxiosError" && (typeof error?.response?.data) === "object") {
        _message = JSON.stringify(error.response.data)
    }
    else if (error?.name !== "Error") {
        if (error.name === "AxiosError"
            && String(error.message).toLowerCase() === "request failed with status code 403"
            && error.response?.status === 403
            && error.response?.statusText === "Forbidden") {
            _message = JSON.stringify({ message: "Request failed with invalid or expired tokens, hit refresh to continue.", status: "error" })
        }
        else _message = JSON.stringify({ message: error.message, status: "error" })
    }
    else _message = error.message
    return Error(_message)
}
export default apiErrorParser