import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from "react-icons/io"

import Meta from "../../../../../components/Meta"
import NewRequestLayout from "../NewRequestLayout"
import expertIcon from "../../../../../assets/icons/request/expert.png"
import ServicesList from "../../../../../assets/ServicesList"
import { newRequestPath, SS_FINANCETRANSFORMATION_OTHER } from "../../../../../assets/constants"

const FinanceTransformation = () => {
    const [value, setValue] = useState("")
    const [otherValue, setOtherValue] = useState("")
    const [error, setError] = useState(false)
    const [showOtherInput, setShowOtherInput] = useState(false)
    const [otherInputError, setOtherInputError] = useState(false)

    const location = useLocation()
    const navigate = useNavigate()
    const handleContinue = () => {
        if (value === "other") {
            if (otherValue) {
                navigate(`${location.pathname + value}/`)
                window.sessionStorage.setItem(SS_FINANCETRANSFORMATION_OTHER, otherValue)
            }
            else {
                setOtherInputError(true)
                setTimeout(() => {
                    setOtherInputError(false)
                }, 2000)
            }
        }
        else if (value) {
            navigate(`${location.pathname + value}/`)
        }
        else {
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 2000)
        }
    }
    return (
        <div className="bg-versich-primary-bg mb-12 py-10 md:py-14 px-3">
            <Meta title="New Request - Finance Transformation Service" description="Web design and development new request" />
            <div className="flex flex-col">
                <NewRequestLayout
                    icon={expertIcon}
                    description="Finance Transformation Service you need."
                    handleBackClick={() => navigate(newRequestPath)}
                    handleContinueClick={handleContinue}
                >
                    <div className="relative">
                        <p className=" text-versich-dark-blue text-lg font-semibold pb-3">What finance transformation service do you need an expert for?</p>
                        <div className="min-h-48 pb-2.5">
                            {FinanceTransformationServices.map((option) => (
                                <label
                                    key={option.uid}
                                    className="flex items-center px-1 py-2 md:p-2 cursor-pointer group"
                                    onClick={() => setValue(option.uid)}
                                >
                                    <input className="appearance-none" name="choice" type="radio" onChange={(e) => setShowOtherInput(e.target.value === "other")} value={option.uid} />
                                    {value === option.uid
                                        ? <IoMdRadioButtonOn className="fill-versich-blue-hover" />
                                        : <IoMdRadioButtonOff className="text-versich-dark-blue group-hover:fill-versich-blue-hover" />
                                    }
                                    <span className="text-versich-dark-blue max-md:text-sm w-full ps-2 group-hover:text-versich-blue-hover transition-all">{option.title}</span>
                                </label>
                            ))}
                            {showOtherInput &&
                                <div className="flex gap-x-2 items-center mt-2.5 ml-1 md:ml-2 mb-2">
                                    <input
                                        className={"max-md:text-sm flex-1 h-10 py-2 px-3 border rounded-md outline-none " + (otherInputError ? "border-red-500" : "border-versich-border")}
                                        name="otherOption"
                                        type="text"
                                        placeholder="Enter your choice"
                                        onChange={(e) => setOtherValue(e.target.value)}
                                    />
                                </div>
                            }
                        </div>
                        {otherInputError && <p className="text-red-500 text-start text-sm mb-3">Please enter a value</p>}
                        {error && <p className="text-red-500 text-start text-sm mb-3">Please select an option</p>}
                    </div>
                </NewRequestLayout>
            </div>
        </div>
    )
}
const FinanceTransformationServices = [
    ...ServicesList[4].subServices,
    {
        title: "Other",
        uid: "other"
    }
]

export default FinanceTransformation