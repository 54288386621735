import { useState, useEffect } from "react"

import CountrySelectionField from "./CountrySelectionField"
import StateSelectionField from "./StateSelectionField"

const LocationSelectionField = ({ notWorldwide, country, state, onLocationChange, regionError }) => {
    const [selectedCountry, setSelectedCountry] = useState("")
    const [selectedState, setSelectedState] = useState(undefined)

    useEffect(() => {
        country && setSelectedCountry(country)
        state && setSelectedState(state)
    }, [country, state])

    // Country seletion
    const onCountryChange = (e) => {
        setSelectedCountry(e.target.value)
        onLocationChange(e.target.value, undefined)
    }

    // State seletion
    const onStateChange = (e) => {
        setSelectedState(e.target.value)
        onLocationChange(selectedCountry, e.target.value)
    }
    return notWorldwide && (
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 text-start">
            <CountrySelectionField name="country" selectedCountry={selectedCountry} onChange={onCountryChange} regionError={regionError} />
            <StateSelectionField name="state" country={selectedCountry} selectedState={selectedState} onChange={onStateChange} regionError={regionError} />
        </div>
    )
}
export default LocationSelectionField
