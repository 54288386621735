import { useFormContext } from "react-hook-form"
import { useEffect } from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

const PhoneNumberField = ({ name, rules, onChange, value = "" }) => {
    const { register, formState, setValue } = useFormContext()
    const { errors } = formState

    useEffect(() => {
        setValue(name, value)
    }, [value, name])

    const handleChange = (phone, country) => {
        setValue(name, phone)
        onChange && onChange(phone, country)
    }
    return (
        <div className="text-start">
            <div className="flex flex-col gap-y-2 text-start">
                <label className="text-versich-label text-sm">Phone number</label>
                <div className="w-full">
                    <PhoneInput
                        name={name}
                        {...register(name, rules)}
                        country={"gb"}
                        onChange={handleChange}
                        value={value}
                        inputProps={{
                            required: true,
                            className: "w-full h-10 pl-14 rounded-md border-[1px] border-versich-border px-3 text-lg focus:outline-none",
                        }}
                        containerClass="relative"
                        buttonClass="absolute inset-y-4 left-0 w-12 text-center px-4 py-2"
                    />
                </div>
                {errors[name] &&
                    <div className="text-sm text-red-500 text-left">
                        {errors[name].message}
                    </div>
                }
            </div>
        </div>
    )
}
export default PhoneNumberField
