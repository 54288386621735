import pinterest from "../assets/social-icons/pinterest.png"
import bark from "../assets/social-icons/bark.png"
import google from "../assets/social-icons/google-business.png"
import facebook from "../assets/social-icons/facebook.png"
import linkedin from "../assets/social-icons/linkedin.png"
import instagram from "../assets/social-icons/instagram.png"
import twitter from "../assets/social-icons/x.png"
import clutch from "../assets/social-icons/clutch.png"

const SocialIconsData = [
    {
        id: 1,
        icon: pinterest,
        url: "https://www.pinterest.com/",
    },
    {
        id: 2,
        icon: bark,
        url: "https://www.bark.com/",
    },
    {
        id: 3,
        icon: google,
        url: "https://www.google.com/",
    },
    {
        id: 4,
        icon: facebook,
        url: "https://www.facebook.com/",
    },
    {
        id: 5,
        icon: linkedin,
        url: "https://www.linkedin.com/",
    },
    {
        id: 6,
        icon: instagram,
        url: "https://www.instagram.com/",
    },
    {
        id: 7,
        icon: twitter,
        url: "https://www.twitter.com/",
    },
    {
        id: 8,
        icon: clutch,
        url: "https://www.clutch.com/",
    },
]
export default SocialIconsData
