import { CLIENT, FREELANCER } from "../../../assets/constants"
import ClientOptions from "../client/ClientOptions"
import FreelancerOptions from "../freelancer/FreelancerOptions"

const UserSpecificOptions = ({ accountType }) => {
    switch (accountType) {
        case CLIENT:
            return <ClientOptions />
        case FREELANCER:
            return <FreelancerOptions />
        default:
            return
    }
}
export default UserSpecificOptions