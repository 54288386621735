import { useSelector } from "react-redux"
import { useLocation, Navigate, Outlet } from "react-router-dom"

import ErrorPage from "../../ErrorPage/ErrorPage"
import { CLIENT, clientPaths } from "../../../assets/constants"

const ClientSetupRoutes = () => {
    const { user } = useSelector(state => state.auth)
    const location = useLocation()
    if (user.account_type !== CLIENT) {
        return <ErrorPage status={403} />
    }
    const route = getProfileLink(user)
    if (route) {
        if (location.pathname !== route) {
            return <Navigate to={route} replace />
        }
        return <Outlet />
    }
    return <ErrorPage status={404} />
}
const getProfileLink = (user) => {
    if (!user.about) {
        return clientPaths.profile
    }
}

export default ClientSetupRoutes