import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../../api"
import apiErrorParser from "../../../utils/apiErrorParser"

export const allExpertAsync = createAsyncThunk("service/MLAI/allExpertAsync", async (params) => {
    try {
        const { data } = await api.expertsMLAI(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const createRequestAsync = createAsyncThunk("account/MLAI/createRequest",
    async ({
        expert, otherExpert, currency, budget, otherBudget, hiringDecision, otherHiringDecision,
        clientEmail, clientName, description, token
    }) => {
        try {
            const { data } = await api.createRequestMLAI({
                expertId: expert,
                otherExpert,
                currencyId: currency,
                estimatedBudgetId: budget,
                otherEstimatedBudget: otherBudget,
                hiringDecisionId: hiringDecision,
                otherHiringDecision,
                clientEmail,
                clientName,
                description,
                csrfmiddlewaretoken: token
            })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })

export const getRequestAsync = createAsyncThunk("account/MLAI/getRequest", async (refId) => {
    try {
        const { data } = await api.getRequestMLAI(refId)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})