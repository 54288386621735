import { useSelector, useDispatch } from "react-redux"
import { useSearchParams, useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import Cookies from "js-cookie"

import Meta from "../../../../../components/Meta"
import FormLoader from "../../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../../components/Inputs/CSRFTokenField"
import ProjectType from "./components/ProjectType"
import ProjectNeed from "./components/ProjectNeed"
import MonetizationStrategy from "./components/MonetizationStrategy"
import AppPlatform from "./components/AppPlatform"
import BusinessType from "../components/BusinessType"
import IndustryType from "../components/IndustryType"
import ProjectDuration from "../components/ProjectDuration"
import Budget from "../components/Budget"
import HiringDecision from "../components/HiringDecision"
import ClientDetails from "../components/ClientDetails"
import Description from "../components/Description"
import {
    loginPath, newRequestPath, requestConfirmationPath,
    COOKIE_NEW_REQUEST_MA,
    TOASTTIMER, TOASTTYPE_ERROR
} from "../../../../../assets/constants"
import { createRequestAsync } from "../../../../../slices/client/service/mobileApp"
import toastify from "../../../../../utils/toastify"

const MobileApp = () => {
    const { user } = useSelector(state => state.auth)
    const [searchParams, _] = useSearchParams()

    const [csrfToken, setCsrfToken] = useState("")
    const [formPosition, setFormPosition] = useState(0)
    const [projectType, setProjectType] = useState("")
    const [otherProjectType, setOtherProjectType] = useState(null)
    const [projectNeed, setProjectNeed] = useState("")
    const [otherProjectNeed, setOtherProjectNeed] = useState(null)
    const [monetizationStrategy, setMonetizationStrategy] = useState("")
    const [otherMonetizationStrategy, setOtherMonetizationStrategy] = useState(null)
    const [appPlatform, setAppPlatform] = useState("")
    const [otherAppPlatform, setOtherAppPlatform] = useState(null)
    const [businessType, setBusinessType] = useState("")
    const [otherBusinessType, setOtherBusinessType] = useState(null)
    const [industryType, setIndustryType] = useState("")
    const [otherIndustryType, setOtherIndustryType] = useState(null)
    const [projectDuration, setProjectDuration] = useState("")
    const [otherProjectDuration, setOtherProjectDuration] = useState(null)
    const [currency, setCurrency] = useState("")
    const [budget, setBudget] = useState("")
    const [otherBudget, setOtherBudget] = useState(null)
    const [hiringDecision, setHiringDecision] = useState("")
    const [otherHiringDecision, setOtherHiringDecision] = useState(null)
    const [clientDetails, setClientDetails] = useState(null)
    const [description, setDescription] = useState("")
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Prefill data from cookie
    useEffect(() => {
        const data = Cookies.get(COOKIE_NEW_REQUEST_MA)
        if (!data) {
            return
        }
        const parsedData = JSON.parse(data)
        parsedData.projectType && setProjectType(parsedData.projectType)
        parsedData.otherProjectType && setProjectType(parsedData.otherProjectType)
        parsedData.projectNeed && setProjectNeed(parsedData.projectNeed)
        parsedData.otherProjectNeed && setOtherProjectNeed(parsedData.otherProjectNeed)
        parsedData.monetizationStrategy && setMonetizationStrategy(parsedData.monetizationStrategy)
        parsedData.otherMonetizationStrategy && setOtherMonetizationStrategy(parsedData.otherMonetizationStrategy)
        parsedData.appPlatform && setAppPlatform(parsedData.appPlatform)
        parsedData.otherAppPlatform && setOtherAppPlatform(parsedData.otherAppPlatform)
        parsedData.businessType && setBusinessType(parsedData.businessType)
        parsedData.otherBusinessType && setOtherBusinessType(parsedData.otherBusinessType)
        parsedData.industryType && setIndustryType(parsedData.industryType)
        parsedData.otherIndustryType && setOtherIndustryType(parsedData.otherIndustryType)
        parsedData.projectDuration && setProjectDuration(parsedData.projectDuration)
        parsedData.otherProjectDuration && setOtherProjectDuration(parsedData.otherProjectDuration)
        parsedData.currency && setCurrency(parsedData.currency)
        parsedData.budget && setBudget(parsedData.budget)
        parsedData.otherBudget && setOtherBudget(parsedData.otherBudget)
        parsedData.hiringDecision && setHiringDecision(parsedData.hiringDecision)
        parsedData.otherHiringDecision && setOtherHiringDecision(parsedData.otherHiringDecision)
        parsedData.clientEmail && setClientDetails({
            email: parsedData.clientEmail,
            name: parsedData.clientName,
        })
        parsedData.description && setDescription(parsedData.description)
    }, [])

    // Prefill client details
    useEffect(() => {
        if (!user) {
            return
        }
        setClientDetails({
            email: user.email,
            ...(user.full_name && { name: user.full_name }),
        })
    }, [user])

    const location = useLocation()
    const currentPath = window.encodeURIComponent(location.pathname)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleSubmit = async (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        if (!description) {
            setError(true)
            return setTimeout(() => {
                setError(false)
            }, 2000)
        }

        setLoading(true)
        const formData = {
            projectType,
            ...(otherProjectType && { otherProjectType }),
            projectNeed,
            ...(otherProjectNeed && { otherProjectNeed }),
            monetizationStrategy,
            ...(otherMonetizationStrategy && { otherMonetizationStrategy }),
            appPlatform,
            ...(otherAppPlatform && { otherAppPlatform }),
            businessType,
            ...(otherBusinessType && { otherBusinessType }),
            industryType,
            ...(otherIndustryType && { otherIndustryType }),
            projectDuration,
            ...(otherProjectDuration && { otherProjectDuration }),
            currency, budget,
            ...(otherBudget && { otherBudget }),
            hiringDecision,
            ...(otherHiringDecision && { otherHiringDecision }),
            clientEmail: clientDetails.email,
            clientName: clientDetails.name,
            description,
            token: csrfToken
        }
        if (user === null) {
            Cookies.set(COOKIE_NEW_REQUEST_MA, JSON.stringify(formData), { expires: 7 })
            return navigate(`${loginPath}?next=${currentPath}&email=${formData.clientEmail}&name=${formData.clientName}`)
        }
        Cookies.remove(COOKIE_NEW_REQUEST_MA)
        await dispatch(createRequestAsync(formData))
            .unwrap()
            .then((data) => {
                if (data.payload) {
                    const dynamicRequestConfimationPath = requestConfirmationPath.replace(":id", data.payload.requestID).replace(":pid", data.payload.pid)
                    navigate(dynamicRequestConfimationPath)
                }
                else toastify("Something went wrong, while creating request!", TOASTTYPE_ERROR, submitBtn)
            })
            .catch(error => {
                const data = JSON.parse(error?.message)
                setErrorMsg(data.message)
                setTimeout(() => {
                    setErrorMsg("")
                    submitBtn.disabled = false
                }, TOASTTIMER)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const totalForms = 11
    return (
        <div className="bg-versich-primary-bg mb-12 py-10 md:py-14 px-3">
            <Meta title="New Request - Mobile App Service" description="Mobile app new request" />
            <form className="flex flex-col" onSubmit={handleSubmit} onKeyDown={e => e.key === "Enter" && !clientDetails && e.preventDefault()}>
                <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                {!projectType
                    ? <ProjectType totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(1)} defaultValue={searchParams.get("projectType")} setProjectType={setProjectType} setOtherProjectType={setOtherProjectType} goBack={() => navigate(newRequestPath)} />
                    : !projectNeed
                        ? <ProjectNeed totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(2)} defaultValue={searchParams.get("projectNeed")} setProjectNeed={setProjectNeed} setOtherProjectNeed={setOtherProjectNeed} goBack={() => setProjectType("")} />
                        : !monetizationStrategy
                            ? <MonetizationStrategy totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(3)} setMonetizationStrategy={setMonetizationStrategy} setOtherMonetizationStrategy={setOtherMonetizationStrategy} goBack={() => setProjectNeed("")} />
                            : !appPlatform
                                ? <AppPlatform totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(4)} defaultValue={searchParams.get("appPlatform")} setAppPlatform={setAppPlatform} setOtherAppPlatform={setOtherAppPlatform} goBack={() => setMonetizationStrategy("")} />
                                : !businessType
                                    ? <BusinessType totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(5)} setBusinessType={setBusinessType} setOtherBusinessType={setOtherBusinessType} goBack={() => setAppPlatform("")} />
                                    : !industryType
                                        ? <IndustryType totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(6)} setIndustryType={setIndustryType} setOtherIndustryType={setOtherIndustryType} goBack={() => setBusinessType("")} />
                                        : !projectDuration
                                            ? <ProjectDuration totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(7)} setProjectDuration={setProjectDuration} setOtherProjectDuration={setOtherProjectDuration} goBack={() => setIndustryType("")} />
                                            : !budget
                                                ? <Budget totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(8)} setCurrency={setCurrency} setBudget={setBudget} setOtherBudget={setOtherBudget} goBack={() => setProjectDuration("")} />
                                                : !hiringDecision
                                                    ? <HiringDecision totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(9)} setHiringDecision={setHiringDecision} setOtherHiringDecision={setOtherHiringDecision} goBack={() => setBudget("")} />
                                                    : !clientDetails
                                                        ? <ClientDetails totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(10)} clientDetails={clientDetails} setClientDetails={setClientDetails} goBack={() => setHiringDecision("")} />
                                                        : <Description totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(11)} description={description} setDescription={setDescription} error={error} goBack={() => setClientDetails(null)} />
                }
            </form>
            <div className="max_screen_w">
                {errorMsg && <div className="text-red-500 text-sm text-right max-w-[900px] !mt-10 mx-auto">{errorMsg}</div>}
            </div>
            {loading && <FormLoader />}
        </div>
    )
}
export default MobileApp