import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../../api"
import apiErrorParser from "../../../utils/apiErrorParser"

export const allMobileProjectTypeAsync = createAsyncThunk("service/MA/allMobileProjectTypeAsync", async (params) => {
    try {
        const { data } = await api.mobileProjectTypesMA(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const allMobileProjectNeedAsync = createAsyncThunk("service/MA/allMobileProjectNeedAsync", async (params) => {
    try {
        const { data } = await api.mobileProjectNeedsMA(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const allMobileMonetizationStrategyAsync = createAsyncThunk("service/MA/allMobileMonetizationStrategyAsync", async (params) => {
    try {
        const { data } = await api.mobileMonetizationStrategyMA(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const allMobileAppPlatformAsync = createAsyncThunk("service/MA/allMobileAppPlatformAsync", async (params) => {
    try {
        const { data } = await api.mobileAppPlatformsMA(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const createRequestAsync = createAsyncThunk("account/MA/createRequest",
    async ({
        projectType, otherProjectType, projectNeed, otherProjectNeed, monetizationStrategy, otherMonetizationStrategy,
        appPlatform, otherAppPlatform, businessType, otherBusinessType, industryType, otherIndustryType,
        projectDuration, otherProjectDuration, currency, budget, otherBudget, hiringDecision, otherHiringDecision,
        clientEmail, clientName, description, token
    }) => {
        try {
            const { data } = await api.createRequestMA({
                projectTypeId: projectType,
                otherProjectType,
                projectNeedId: projectNeed,
                otherProjectNeed,
                monetizationStrategyId: monetizationStrategy,
                otherMonetizationStrategy,
                appPlatformId: appPlatform,
                otherAppPlatform,
                businessTypeId: businessType,
                otherBusinessType,
                industryTypeId: industryType,
                otherIndustryType,
                projectDurationId: projectDuration,
                otherProjectDuration,
                currencyId: currency,
                estimatedBudgetId: budget,
                otherEstimatedBudget: otherBudget,
                hiringDecisionId: hiringDecision,
                otherHiringDecision,
                clientEmail,
                clientName,
                description,
                csrfmiddlewaretoken: token
            })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })

export const getRequestAsync = createAsyncThunk("account/MA/getRequest", async (refId) => {
    try {
        const { data } = await api.getRequestMA(refId)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})