import { useSelector, useDispatch } from "react-redux"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import Meta from "../../../../components/Meta"
import FormLoader from "../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import ComboServicesInputField from "../../../../components/Inputs/ComboServicesInputField"
import { dashboardPath } from "../../../../assets/constants"
import { freelancerOtherServiceUpdateAsync } from "../../../../slices/freelancer/otherService"

const OtherServicesForm = () => {
    const { user } = useSelector((state) => state.auth)
    const [csrfToken, setCsrfToken] = useState("")
    const [selectedServices, setSelectedServices] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (selectedServices) {
            const formData = {
                token: csrfToken,
                serviceIds: selectedServices
            }
            setLoading(true)
            await dispatch(freelancerOtherServiceUpdateAsync(formData))
                .unwrap()
                .then(() => navigate(dashboardPath))
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        }
        else navigate(dashboardPath)
    }
    return (
        <div className="py-10 md:py-14 px-3 mb-12 bg-versich-primary-bg flex items center justify-center">
            <Meta title="Setup - Other Services" description="Kindly proceed to add additional services for more leads" />
            <form className="w-full bg-white shadow-form py-5 md:py-10 px-5 md:px-10 max-w-[580px] rounded-md space-y-6" onSubmit={handleSubmit}>
                <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                <div className="space-y-5 text-start">
                    <h2 className="text-xl text-versich-dark-blue font-semibold">Maximize your leads</h2>
                    <p>Add other services you can provide</p>
                </div>
                <div className="text-start">
                    <p>You’ve signed up for</p>
                    <button className="mt-2 flex gap-x-4 rounded-2xl cursor-default bg-gray-300 py-2 px-4 text-black items-center">{user.primaryService.title}</button>
                </div>
                <div className="text-start">
                    <p>We will also show you leads from</p>
                    <ComboServicesInputField onSelect={(e) => setSelectedServices(e)} />
                </div>
                {errorMsg && <div className="text-sm text-red-500 text-left !mt-10">{errorMsg}</div>}
                <button className="w-full py-2 bg-versich-blue text-white rounded-lg hover:bg-versich-blue-hover transition-all duration-300 ease-in-out" type="submit">Submit</button>
                {/* <div className="flex bg-[#D9EBFC] py-8 px-4 items-center justify-between rounded-xl gap-x-2">
                    <div className="text-start">
                        <p className="text-versich-light-blue text-3xl md:text-5xl font-medium">799</p>
                        <p className="text-xl text-versich-light-blue">Current available leads</p>
                    </div>
                    <button className="bg-versich-blue hover:bg-versich-blue-hover py-3 px-6 text-white w-40 text-xl rounded-xl h-14">See Leads</button>
                </div> */}
            </form>
            {loading && <FormLoader />}
        </div>
    )
}
export default OtherServicesForm
