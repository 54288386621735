import { FormProvider, useForm } from "react-hook-form"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"

import Meta from "../../../../components/Meta"
import FormLoader from "../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import ServicesList from "../../../../assets/ServicesList"
import { dashboardPath, TOASTTIMER } from "../../../../assets/constants"
import { freelancerPrimaryServiceSetAsync } from "../../../../slices/freelancer/primaryService"

const PrimaryServiceForm = () => {
    const methods = useForm()
    const [csrfToken, setCsrfToken] = useState("")
    const [selectedService, setSelectedService] = useState("")
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (selectedService) {
            const formData = {
                token: csrfToken,
                serviceid: selectedService
            }
            setLoading(true)
            await dispatch(freelancerPrimaryServiceSetAsync(formData))
                .unwrap()
                .then(() => methods.reset())
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        }
        else {
            setError(true)
            setTimeout(() => {
                setError(false)
            }, TOASTTIMER)
        }
    }
    return (
        <FormProvider {...methods}>
            <Meta title="Setup - Primary Service" description="Complete leads registration" />
            <div className="bg-versich-primary-bg mb-12 py-10 md:py-14 px-3 overflow-hidden">
                <form className="flex flex-col" onSubmit={handleSubmit}>
                    <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                    <div className="max_screen_w bg-white flex flex-col gap-3 flex-1 px-4 py-6 shadow-form rounded-md">
                        <label className="text-versich-dark-blue font-semibold py-2 flex justify-center text-lg">Choose your primary service?</label>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-4 gap-x-8 py-8 px-4">
                            {ServicesList.map((service) => (
                                <div
                                    key={service.id}
                                    className={`flex flex-col justify-center items-center gap-y-2 h-44 py-4 px-3 border-[3px] ${selectedService === service.uid
                                        ? "border-versich-blue/80 bg-versich-blue/15"
                                        : "border-gray-100 hover:border-versich-blue/20"
                                        } cursor-pointer shadow-sticker rounded-xl`
                                    }
                                    onClick={() => setSelectedService(service.uid)}
                                >
                                    <input className="appearance-none" name="selectedService" type="radio" value={service.uid} />
                                    <img src={service.icon} alt={service.name} className="w-16 h-16 drop-shadow contrast-150" />
                                    <span className="text-versich-dark-blue text-center text-sm">{service.name}</span>
                                </div>
                            ))}
                        </div>
                        {error && <p className="text-red-500 text-start text-sm mb-3">Please select a service.</p>}
                        {errorMsg && <div className="text-sm text-red-500 text-left !mt-10">{errorMsg}</div>}
                        <hr className="pb-3" />
                        <div className="w-full flex justify-between">
                            <button className="bg-slate-100 hover:bg-slate-200 text-gray-900 border border-versich-border/20 rounded-md py-2 lg:py-2.5 px-6 lg:px-8" type="button" onClick={() => navigate(dashboardPath)}>Back</button>
                            <button className="bg-versich-blue hover:bg-versich-blue-hover text-white rounded-md py-2 lg:py-2.5 px-6 lg:px-8" type="submit">Next</button>
                        </div>
                    </div>
                </form>
            </div>
            {loading && <FormLoader />}
        </FormProvider>
    )
}
export default PrimaryServiceForm